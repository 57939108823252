import { CheckBox, CancelOutlined } from '@mui/icons-material'
import { useVehicle } from 'contexts'
import React from 'react'
import { Link } from 'react-router-dom'
import { useGetFilesUrls } from 'utils/hooks/queries'

const VehicleSheetDocuments = () => {
  const vehicle = useVehicle()
  const displayedKeys = {
    GrayCard: 'Carte grise',
    TechnicalCheck: 'Contrôle technique',
    MaintenanceLogBook: "Carnet d'entretien",
    ExpertAssessment: 'Bilan Expert',
    ExplodedViewer: 'Explosé carrosserie',
  }

  const validDocuments = vehicle.documents.filter(
    (doc) => Object.keys(displayedKeys).includes(doc.doctype as string) && doc.fileStorageId,
  )

  const validDocumentIds = validDocuments?.map((doc) => doc.fileStorageId)

  const { idsAndUrls } = useGetFilesUrls(validDocumentIds)

  const rebuiltData = idsAndUrls.map((doc) => {
    const matchingValidDoc = validDocuments.find((validDoc) => validDoc.fileStorageId === doc.id)
    return { ...doc, doctype: matchingValidDoc?.doctype }
  })

  return (
    <div>
      {Object.keys(displayedKeys).map((key) => {
        const translatedKey = displayedKeys[key as keyof typeof displayedKeys]

        const displayedDoc = rebuiltData.find((doc) => doc.doctype === key)
        const isValid = Boolean(displayedDoc)
        const displayedColor: string = isValid ? 'green' : 'red'
        const icon = isValid ? (
          <CheckBox
            key={`checkbox ${key}`}
            fontSize="large"
            className="mr-2 "
          />
        ) : (
          <CancelOutlined
            key={`checkbox ${key}`}
            fontSize="large"
            className="mr-2 "
          />
        )

        if (isValid) {
          return (
            <Link
              key={key}
              to={displayedDoc?.url as string}
              target="blank"
              rel="noopener noreferrer"
              title="Voir le fichier"
              className="grid w-full grid-cols-12 my-2 "
              style={{
                color: displayedColor,
              }}
            >
              <span className="flex items-center col-span-7 font-bold ">{translatedKey}</span>
              <div className="flex items-center col-span-5 ml-1">
                {icon}
                <p>Voir</p>
              </div>
            </Link>
          )
        }
        return (
          <div
            key={key}
            className="grid w-full grid-cols-12 my-2 "
            style={{
              color: displayedColor,
              cursor: 'default',
            }}
          >
            <span className="flex items-center col-span-7 font-bold ">{translatedKey}</span>
            <div className="flex items-center col-span-5 ml-1">
              {icon}
              <p>Absent</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default VehicleSheetDocuments
