import React from 'react'
import DownloadIcon from '@mui/icons-material/Download'

interface DownLoadButtonProps {
  csvData: string
  fileName: string
}
const DataTableDownLoadButton = ({ csvData, fileName }: DownLoadButtonProps) => {
  const downloadCSV = () => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName || 'data.csv'

    document.body.appendChild(a)
    a.click()

    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }
  return (
    <button
      type="button"
      onClick={downloadCSV}
      className="flex items-center justify-center"
    >
      <DownloadIcon /> Télécharger csv
    </button>
  )
}

export default DataTableDownLoadButton
