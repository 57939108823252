/* eslint-disable camelcase */
import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetAuctionVehicles } from 'utils/hooks/queries'
import { TableData, useAlert } from 'contexts'
import { useLoading } from 'utils/hooks'
import { generateAuctionsTableTitle } from 'utils/helpers/generateAuctionsTable'

import useVehicleModal from 'utils/hooks/useVehicleModal'
import FetchErrorParagraph from 'components/common/FetchErrorParagraph'
import EmptyDataParagraph from 'components/common/EmptyDataParagraph'
import MaterialTable from 'components/dataTable/MaterialTable'
import { MRT_Row } from 'material-react-table'

const SaleResultDetailPage = () => {
  const { Loading } = useLoading()
  const { displayAlert } = useAlert()
  const { setOpenModal, VehicleModal } = useVehicleModal()
  const { auctionNo } = useParams()

  const searchStr: string = auctionNo !== undefined ? auctionNo : ''

  const {
    data: futureAuctionVehicles,
    auction: futureAuction,
    isLoading,
    error: getError,
  } = useGetAuctionVehicles(searchStr)

  const isDataEmpty = futureAuction === undefined
  const [vehicleModalActiveId, setVehicleModalActiveId] = React.useState<string>('')

  React.useEffect(() => {
    if (getError?.message) {
      displayAlert(`${getError.message}`, 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getError])

  if (isLoading) return <Loading />
  if (getError) return <FetchErrorParagraph />
  if (isDataEmpty) return <EmptyDataParagraph />

  const handleClick = (row: MRT_Row<TableData>) => {
    setVehicleModalActiveId(row.original.no as string)
    setOpenModal(true)
  }

  return (
    <>
      <MaterialTable
        data={futureAuctionVehicles}
        onClick={handleClick}
        key="futureSales"
        title={generateAuctionsTableTitle(futureAuction)}
      />
      <VehicleModal
        id={vehicleModalActiveId}
        onClose={() => setVehicleModalActiveId('')}
      />
    </>
  )
}

export default SaleResultDetailPage
