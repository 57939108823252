import { GroupsOutlined, Person } from '@mui/icons-material'
import { AutocompleteChangeReason } from '@mui/material'
import { ComboBox } from 'components/common'
import { useAuthenticatedUser, useWaitingFilesCount, useEntities } from 'contexts'
import { Entity, defaultEntity } from 'models'
import React from 'react'
import { allAccountsLabel } from 'utils/constants'
import { entityDisplayName } from 'utils/helpers'
import { useGetContact } from 'utils/hooks/queries/useGetContact'
import { theme } from 'utils/theme'
import { NavBarSellerFamily } from './NavBarSellerFamily'

const NavBarSellerSection = () => {
  // TODO: Move logic out of component

  const { authenticatedUser, isLoginB2C } = useAuthenticatedUser()
  const { setWaitingFilesCount } = useWaitingFilesCount()

  const {
    selectedParent,
    selectedChild,
    childrenEntities,
    allEntities,
    isOrphanSelected,
    setSelectedParent,
    setSelectedChild,
  } = useEntities()

  const { data: contact } = useGetContact()

  const handleBtoC = () => {
    const userEntity = allEntities.find((ent: Entity) => ent.id === contact?.assignedEntityId)

    if (!userEntity) {
      return
    }
    const hasChildren = userEntity.childrenCount && userEntity.childrenCount !== 0
    const hasParent = userEntity.parentId !== ''

    if (hasChildren || !hasParent) {
      setSelectedParent(userEntity)
      return
    }

    const parentEntity: Entity = allEntities.filter(
      (ent: Entity) => ent.id === userEntity.parentId,
    )[0]
    setSelectedParent(parentEntity)
  }

  React.useEffect(() => {
    if (isLoginB2C && Boolean(allEntities)) {
      handleBtoC()
    }
  }, [authenticatedUser, allEntities])

  React.useEffect(() => {
    setSelectedChild(null)
  }, [selectedParent])

  const handleChildEntitySelection = (
    _event: React.SyntheticEvent<Element, Event>,
    option: Entity | null | undefined,
    reason: AutocompleteChangeReason,
  ) => {
    setSelectedChild(null)
    setWaitingFilesCount(0)

    if (reason === 'clear') {
      return
    }
    setSelectedChild(option as Entity)
  }

  // Add all sellers option
  const allChildrenEntityLabel: Entity = {
    ...defaultEntity,
    commercialNameInt: allAccountsLabel,
  }

  const allChildren = isOrphanSelected
    ? childrenEntities
    : [allChildrenEntityLabel, ...childrenEntities]

  return (
    <>
      <GroupsOutlined className="ml-3" />
      {!isLoginB2C ? (
        <NavBarSellerFamily />
      ) : (
        <p className="ml-2">{entityDisplayName(selectedParent)}</p>
      )}

      {childrenEntities.length > 0 ? (
        <>
          <Person className="ml-3" />
          <ComboBox
            options={allChildren}
            getOptionLabel={(option: Entity) => entityDisplayName(option)}
            getOptionKey={(option: Entity) => option.id}
            selectedOption={selectedChild || allChildrenEntityLabel}
            onSelection={handleChildEntitySelection}
            key="sellerChild"
            boxStyle={
              selectedChild ? undefined : { border: `3px solid ${theme.palette.primary.main}` }
            }
          />
        </>
      ) : null}
    </>
  )
}

export default NavBarSellerSection
