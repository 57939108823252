import { AuctionLineHistory, defaultAuctionLineHistory } from '../auctionLineHistory'
import { AuctionLineInformation, defaultAuctionLineInformation } from '../auctionLineInformation'
import { AuctionLinePricing, defaultAuctionLinePricing } from '../auctionLinePricing'
import { Prestation } from '../prestation'
import { Status, defaultStatus } from '../status'
import { VehicleDetail, defaultVehicleDetail } from '../vehicleDetail'
import { VehicleDocument } from '../vehicleDocument'
import { VehiclePartial, defaultVehiclePartial } from '../vehiclePartial'

type AuctionLine = {
  // OK
  no: string
  entityId: string
  contactId: string
  vehiclePartial: VehiclePartial
  vehicleDetail: VehicleDetail
  status: Status
  documents: Array<VehicleDocument> | null
  photos: Array<VehicleDocument> | null
  prestations: Array<Prestation> | null

  // Different in Vehicle
  information: AuctionLineInformation
  pricing: AuctionLinePricing

  // Doesn't exist in Vehicle
  localisationInternet: string | null
  subAuction: string | null
  subAuctionId: number | null
  passingOrderNo: number
  selectionCommissairePriseur: boolean
  history: AuctionLineHistory
  import: boolean
  ligthouseLot: boolean
}

const defaultAuctionLine: AuctionLine = {
  no: '',
  entityId: '',
  contactId: '',
  vehiclePartial: defaultVehiclePartial,
  vehicleDetail: defaultVehicleDetail,
  localisationInternet: null,
  information: defaultAuctionLineInformation,
  prestations: [],
  status: defaultStatus,
  documents: [],
  photos: [],
  pricing: defaultAuctionLinePricing,
  subAuction: null,
  subAuctionId: null,
  passingOrderNo: 0,
  ligthouseLot: false,
  import: false,
  selectionCommissairePriseur: false,
  history: defaultAuctionLineHistory,
}

export type { AuctionLine }
export { defaultAuctionLine }
