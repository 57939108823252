import { useAuthenticatedUser } from 'contexts'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { PATHS } from 'utils/constants'

const UnauthenticatedRoute = () => {
  const { authenticatedUser } = useAuthenticatedUser()
  const isAuthenticated = authenticatedUser.clientPrincipal !== null

  return isAuthenticated ? <Navigate to={PATHS.home} /> : <Outlet />
}

export default UnauthenticatedRoute
