import React from 'react'

import { Customizations } from 'models'
import VehicleSheetLinesGroup from '../VehicleSheetLinesGroup'

const VehicleSheetSectionModelInfo = () => {
  const items: (keyof Customizations)[] = ['equipments', 'documents']

  return (
    <VehicleSheetLinesGroup
      arrayOfKeys={items}
      // className="grid items-start max-h-full gap-2 border border-orange-500 grid-rows-10"
      className="grid grid-cols-1 grid-rows-3 gap-2 overflow-hidden "
    />
  )
}

export default VehicleSheetSectionModelInfo
