import React from 'react'

import { Customizations } from 'models/schema'
import VehicleSheetLinesGroup from '../VehicleSheetLinesGroup'

const VehicleSheetSectionCondition = () => {
  const conditionSection: (keyof Customizations)[] = [
    'providedFRE',
    'priceReserve',
    'mileage',
    'estimation',
    'argusAgeAddedValue',
    'argusMileageAddedValue',
    'auctionStatus',
    'administrativeStatus',
  ]

  return (
    <VehicleSheetLinesGroup
      arrayOfKeys={conditionSection}
      className="grid content-around h-full grid-cols-3 col-span-3 row-span-2 gap-2"
    />
  )
}

export default VehicleSheetSectionCondition
