import { Customizations } from 'models'

const auctionLightTableLabels: Customizations = {
  auctionDate: {
    label: 'Date de vente',
    isDisplayed: true,
  },
  description: {
    label: 'Nom de la vente',
    isDisplayed: true,
  },

  lib: {
    label: 'Nombre de véhicules',
    isDisplayed: true,
  },
}

export { auctionLightTableLabels }
