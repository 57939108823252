import { Customizations } from 'models'

const searchResultTableLabels: Customizations = {
  no: {
    label: 'Réf',
    isDisplayed: true,
  },
  licensePlate: {
    label: 'Immatriculation',
    isDisplayed: true,
  },
  make: {
    label: 'Marque',
    isDisplayed: true,
  },
  bodyType: {
    label: 'Type com',
    isDisplayed: true,
  },
  dateDelivery: {
    label: 'Entrée sur le parc',
    isDisplayed: true,
  },
  auctionStartDateTime: {
    label: 'Date de vente',
    isDisplayed: true,
  },
  administrativeStatus: {
    label: 'Statut général',
    isDisplayed: true,
  },
  auctionStatus: {
    label: 'Sous-Statut',
    isDisplayed: true,
  },
  estimation: {
    label: 'Estimation',
    isDisplayed: true,
  },
  argusMileageAddedValue: {
    label: 'Cote Km',
    isDisplayed: true,
  },
  argusAgeAddedValue: {
    label: 'Cote Datée',
    isDisplayed: true,
  },
}

export { searchResultTableLabels }
