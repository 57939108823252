import { Customizations } from 'models'

const waitingFilesPageTableLabels: Customizations = {
  blockingReason: {
    label: 'Raison',
    isDisplayed: true,
  },
  nbJ: {
    label: 'NJ',
    isDisplayed: true,
  },
  no: {
    label: 'Réf',
    isDisplayed: true,
  },
  licensePlate: {
    label: 'Immat',
    isDisplayed: true,
  },
  bodyType: {
    label: 'Type Commercial',
    isDisplayed: true,
  },
  make: {
    label: 'Marque',
    isDisplayed: true,
  },
  model: {
    label: 'Modèle',
    isDisplayed: true,
  },
  proprietaireDebiteur: {
    label: 'Propriétaire / déb',
    isDisplayed: true,
  },
  dateProduction: {
    label: 'Date création',
    isDisplayed: true,
  },
}

export { waitingFilesPageTableLabels }
