import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { Fade } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '5px',
  width: '40%',
  height: '90%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}
type GenericModalProps = {
  handleKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
}

const useGenericModal = (props?: GenericModalProps) => {
  const [openModal, setOpenModal] = React.useState(false)

  const closeModal = () => {
    setOpenModal(false)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      closeModal()
    }
  }

  const DisplayedModal: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
      <Modal
        open={openModal}
        onKeyDown={props?.handleKeyDown || handleKeyDown}
        aria-labelledby="generic-modal-title"
        aria-describedby="generic-modal-description"
      >
        <Fade in={openModal}>
          <Box
            id="modalBox"
            className="p-6"
            sx={style}
          >
            <button
              aria-label="close modal"
              type="button"
              onClick={closeModal}
              className="fixed overflow-y-auto rounded-lg bg-secondary-light right-2 top-2"
            >
              <CloseIcon sx={{ fontSize: 36 }} />
            </button>
            {children}
          </Box>
        </Fade>
      </Modal>
    )
  }

  return {
    DisplayedModal,
    setOpenModal,
    openModal,
  }
}

export default useGenericModal
