import { DateISO8601 } from 'models/utils'
import {
  AuctionCalendarList,
  AuctionLine,
  AuctionRoom,
  defaultAuctionLine,
  defaultSubAuction,
  SubAuction,
} from '../subtypes'

type AuctionDTO = {
  no: string
  description: string
  descriptionDetaillee: string | null
  auctionStatus: number
  auctionRoom: AuctionRoom
  auctionCalendarList: Array<AuctionCalendarList> | null
  auctionType: number
  auctionTemplate: string
  isInternetAuction: boolean
  auctionStartDateTime: DateISO8601 | '' | null
  auctionEndDateTime: DateISO8601 | '' | null
  subAuctions: Array<SubAuction>
  isPricesIncludingVAT: boolean
  adjudicatedAmount: string
  retiredAmount: string
  numberOfLotInAuction: number
  numberOfLotSold: number
  numberOfLotRetired: number
  numberOfLotWithoutReserve: 0
  categories: unknown | null
  photos: unknown | null
  bidPriceIncrement: number
  vatRate: string
  fixedFees: string
  minuteSup: number
  auctionLines: Array<AuctionLine>
  venteExclusive: boolean
}

type AuctionLightSpecifics = {
  auctionDate: string | null | ''
  isOnlineAuction: boolean
}

type AuctionLightDTO = Pick<
  AuctionDTO,
  'no' | 'description' | 'numberOfLotInAuction' | 'numberOfLotWithoutReserve' | 'numberOfLotSold'
> &
  AuctionLightSpecifics

const defaultAuctionDTO: AuctionDTO = {
  no: '',
  description: '',
  descriptionDetaillee: null,
  auctionStatus: 0,
  auctionRoom: null,
  auctionCalendarList: [],
  auctionType: 0,
  auctionTemplate: '',
  isInternetAuction: false,
  auctionStartDateTime: '',
  subAuctions: [defaultSubAuction],
  isPricesIncludingVAT: false,
  adjudicatedAmount: '',
  retiredAmount: '',
  numberOfLotInAuction: 0,
  numberOfLotSold: 0,
  numberOfLotRetired: 0,
  categories: null,
  photos: null,
  auctionLines: [defaultAuctionLine],
  bidPriceIncrement: 0,
  vatRate: '',
  fixedFees: '',
  minuteSup: 0,
  venteExclusive: false,
  auctionEndDateTime: null,
  numberOfLotWithoutReserve: 0,
}
const defaultAuctionLightDTO: AuctionLightDTO = {
  no: defaultAuctionDTO.no,
  numberOfLotInAuction: defaultAuctionDTO.numberOfLotInAuction,
  numberOfLotWithoutReserve: defaultAuctionDTO.numberOfLotWithoutReserve,
  numberOfLotSold: defaultAuctionDTO.numberOfLotSold,
  description: defaultAuctionDTO.description,
  auctionDate: null,
  isOnlineAuction: false,
}

export { defaultAuctionDTO, defaultAuctionLightDTO, defaultAuctionLine, defaultSubAuction }
export type {
  AuctionDTO,
  AuctionLightDTO,
  AuctionCalendarList,
  AuctionLine,
  AuctionRoom,
  SubAuction,
}
