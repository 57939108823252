import { Entity, Vehicle } from 'models'
import { dummyQueryAdapter } from './dummyQueryAdapter'
import { IQueries, UpdateSchemaBody } from './queryInterface'

const queryService = (adapter: IQueries): IQueries => ({
  getContact: async (contactId: string) => adapter.getContact(contactId),

  getAllEntities: () => adapter.getAllEntities(),

  getAuction: async (auctionNo: string) => adapter.getAuction(auctionNo),

  getAuctionsLight: (filteringEntityId: Entity['id'], timeLine) =>
    adapter.getAuctionsLight(filteringEntityId, timeLine),

  getWaitingFilesFromEntity: async (filteringEntityId: Entity['id']) =>
    adapter.getWaitingFilesFromEntity(filteringEntityId),

  getStockFromEntity: async (filteringEntityId: Entity['id']) =>
    adapter.getStockFromEntity(filteringEntityId),

  getVehicles: async (
    filteringEntityId: Entity['id'],
    key: 'no' | 'licensePlate' | 'vin',
    value: string,
  ) => adapter.getVehicles(filteringEntityId, key, value),

  getStatusesObject: async () => adapter.getStatusesObject(),

  getVehicleByNo: (no: Vehicle['no']) => adapter.getVehicleByNo(no),

  getFilesStorageUrl: (ids: Array<string>) => adapter.getFilesStorageUrl(ids),

  updateVehicle: (body: Partial<Vehicle>) => adapter.updateVehicle(body),

  getSchema: async (userId: string, companyId: string) => adapter.getSchema(userId, companyId),

  updateSchema: async (body: UpdateSchemaBody) => adapter.updateSchema(body),
})

const currentQueryService = queryService(dummyQueryAdapter)

export { queryService, currentQueryService }
