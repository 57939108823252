/* eslint-disable camelcase */
import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetAuctionVehicles } from 'utils/hooks/queries'
import { FutureSaleVehiclesContext, TableData, useAlert } from 'contexts'
import { useLoading } from 'utils/hooks'
import { generateAuctionsTableTitle } from 'utils/helpers/generateAuctionsTable'

import useVehicleModal from 'utils/hooks/useVehicleModal'
import EmptyDataParagraph from 'components/common/EmptyDataParagraph'
import FetchErrorParagraph from 'components/common/FetchErrorParagraph'
import { MRT_Row } from 'material-react-table'
import MaterialTable from 'components/dataTable/MaterialTable'

const FutureSaleVehiclesPage = () => {
  // TODO: Create AuctionLineUniversalData
  const { Loading } = useLoading()
  const { displayAlert } = useAlert()
  const { setOpenModal, VehicleModal } = useVehicleModal()
  const { auctionNo } = useParams()
  const searchStr: string = auctionNo !== undefined ? auctionNo : ''
  const {
    data: futureAuctionVehicles,
    auction: futureAuction,
    isLoading,
    error: getError,
  } = useGetAuctionVehicles(searchStr)

  const [vehicleModalActiveId, setVehicleModalActiveId] = React.useState<string>('')

  const handleClick = (row: MRT_Row<TableData>) => {
    setVehicleModalActiveId(row.original.no as string)
    setOpenModal(true)
  }
  const isDataEmpty = futureAuction === undefined

  React.useEffect(() => {
    if (getError?.message) {
      displayAlert(`${getError.message}`, 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getError])

  if (isLoading) return <Loading />
  if (getError) return <FetchErrorParagraph />
  if (isDataEmpty) return <EmptyDataParagraph />

  return (
    <FutureSaleVehiclesContext.Provider value={futureAuctionVehicles}>
      <MaterialTable
        data={futureAuctionVehicles}
        title={generateAuctionsTableTitle(futureAuction)}
        onClick={handleClick}
        key="futureSalesDetails"
      />
      <VehicleModal
        id={vehicleModalActiveId}
        onClose={() => setVehicleModalActiveId('')}
      />
    </FutureSaleVehiclesContext.Provider>
  )
}

export default FutureSaleVehiclesPage
