/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
import React from 'react'
import { Schema, TableData, TableKey, Customizations, Visibility } from 'models/schema'
import { useGetSchema } from 'utils/hooks/queries/useGetSchema'
import { compareObjectsDifferent, compareObjectsEqual } from 'utils/helpers'
import { MRT_ColumnDef } from 'material-react-table'
import ReserveInput from 'components/dataTable/ReserveInput'
import { defaultSchema } from 'utils/constants'

type SchemaReducerState = {
  schema: Schema
  currentTableKey: TableKey
  order: Array<keyof Customizations>
  visibility: Visibility
  columns: MRT_ColumnDef<TableData, unknown>[]
}

const defaultSchemaReducerState: SchemaReducerState = {
  schema: defaultSchema,
  currentTableKey: 'futureSales',
  order: [],
  visibility: {},
  columns: [],
}

const schemaActions = {
  SET_CURRENT_TABLE_KEY: 'SET_CURRENT_TABLE_KEY',
  SET_SCHEMA: 'SET_SCHEMA',
  SET_ORDER: 'SET_ORDER',
  SET_VISIBILITY: 'SET_VISIBILITY',
  FILL_ORDER_FROM_SCHEMA: 'FILL_ORDER_FROM_SCHEMA',
  FILL_COLUMNS_FROM_SCHEMA: 'FILL_COLUMNS_FROM_SCHEMA',
  FILL_VISIBILITY_FROM_SCHEMA: 'FILL_VISIBILITY_FROM_SCHEMA',
  UPDATE_SCHEMA_ORDER: 'UPDATE_SCHEMA_ORDER',
  UPDATE_SCHEMA_VISIBILITY: 'UPDATE_SCHEMA_VISIBILITY',
} as const

type SchemaActionsKeys = keyof typeof schemaActions
type SchemaActions = (typeof schemaActions)[SchemaActionsKeys]

type SchemaReducerAction = {
  type: SchemaActions
  property?: keyof Customizations
  payload?: unknown
}

const getStateCurrentCustomization = (state: SchemaReducerState) => {
  const personnalCustomizations = state.schema.tableMappings.find(
    (mapping) => mapping.tableName === state.currentTableKey,
  )?.customizations
  const defaultCustomizations = defaultSchema.tableMappings.find(
    (mapping) => mapping.tableName === state.currentTableKey,
  )?.customizations as Customizations
  return personnalCustomizations || defaultCustomizations
}
const getStateCurrentOrder = (state: SchemaReducerState) => {
  return Object.keys(getStateCurrentCustomization(state)) as Array<keyof Customizations>
}
const getStateCurrentVisibility = (state: SchemaReducerState) => {
  const currentCustomization = getStateCurrentCustomization(state)
  return Object.keys(currentCustomization).reduce((acc, key) => {
    return {
      ...acc,
      [key]: currentCustomization[key as keyof typeof currentCustomization]?.isDisplayed as boolean,
    }
  }, {}) as Visibility
}

const generateColumns = (state: SchemaReducerState) => {
  const currentCustomization = getStateCurrentCustomization(state)
  const currentOrder = getStateCurrentOrder(state)
  return currentOrder.map((property) => {
    const defaultCol = {
      accessorKey: property,
      id: property,
      header: currentCustomization[property]?.label || '',
    }
    switch (property) {
      // TODO: Uncomment when Reserve modification OK
      case 'reserveHF': {
        return state.currentTableKey === 'futureSalesDetail'
          ? {
              ...defaultCol,
              Cell: ({ ...row }) => {
                return (
                  <ReserveInput
                    value={row.row.original.reserveHF}
                    vehicleNo={row.row.original.no}
                    key={row.row.original.no}
                  />
                )
              },
            }
          : defaultCol
      }
      default:
        return defaultCol
    }
  })
}

const reducer = (state: SchemaReducerState, action: SchemaReducerAction): SchemaReducerState => {
  switch (action.type) {
    case schemaActions.SET_SCHEMA:
      return { ...state, schema: action.payload as unknown as Schema }
    case schemaActions.SET_ORDER:
      return { ...state, order: action.payload as unknown as Array<keyof Customizations> }
    case schemaActions.SET_VISIBILITY:
      return { ...state, visibility: action.payload as unknown as Visibility }
    case schemaActions.SET_CURRENT_TABLE_KEY:
      const figureOutWhichPanel: TableKey = (() => {
        const loc = window.location.pathname
        if (loc.includes('future-sales'))
          return loc.endsWith('future-sales') ? 'futureSales' : 'futureSalesDetail'
        if (loc.includes('sales-result'))
          return loc.endsWith('sales-result') ? 'salesResult' : 'salesResultDetail'
        if (loc === '/waiting-files') return 'waitingFiles'
        if (loc === '/stock') return 'stock'
        return 'search'
      })()
      return figureOutWhichPanel === state.currentTableKey
        ? state
        : {
            ...state,
            currentTableKey: figureOutWhichPanel,
          }

    case schemaActions.FILL_ORDER_FROM_SCHEMA: {
      const stateOrder = state.order
      const schemaOrder = getStateCurrentOrder(state)
      return compareObjectsDifferent(stateOrder, schemaOrder)
        ? {
            ...state,
            order: schemaOrder,
          }
        : state
    }

    case schemaActions.FILL_COLUMNS_FROM_SCHEMA: {
      if (state.order.length === 0) {
        return state
      }
      const stateColumns = state.columns
      const newColumns = generateColumns(state)

      return compareObjectsEqual(newColumns, stateColumns)
        ? state
        : {
            ...state,
            columns: newColumns,
          }
    }

    case schemaActions.FILL_VISIBILITY_FROM_SCHEMA: {
      if (state.order.length === 0) {
        return state
      }
      const stateVisibility = state.visibility
      const schemaVisibility = getStateCurrentVisibility(state)
      return compareObjectsDifferent(stateVisibility, schemaVisibility)
        ? {
            ...state,
            visibility: schemaVisibility,
          }
        : state
    }

    case schemaActions.UPDATE_SCHEMA_ORDER: {
      const newOrder = state.order
      const currentCustomization = getStateCurrentCustomization(state)
      const newCustomization = newOrder.reduce((acc, value) => {
        return { ...acc, [value]: currentCustomization[value] }
      }, {})

      if (compareObjectsEqual(newCustomization, currentCustomization)) {
        return state
      }
      return {
        ...state,
        schema: {
          ...state.schema,
          tableMappings: state.schema.tableMappings.map((mapping) =>
            mapping.tableName === state.currentTableKey
              ? { ...mapping, customizations: newCustomization }
              : mapping,
          ),
        },
      }
    }

    case schemaActions.UPDATE_SCHEMA_VISIBILITY: {
      if (state.order.length === 0) {
        return state
      }
      const newVisibility = state.visibility
      const newOrder = state.order

      const currentCustomization = getStateCurrentCustomization(state)

      const newCustomization: Customizations = newOrder.reduce((acc, value) => {
        if (!Object.keys(newVisibility).includes(value)) return acc

        return {
          ...acc,
          [value]: {
            label: currentCustomization[value as keyof typeof currentCustomization]
              ?.label as string,
            isDisplayed: newVisibility[value as keyof typeof newVisibility],
          },
        }
      }, {})

      return {
        ...state,
        schema: {
          ...state.schema,
          tableMappings: state.schema.tableMappings.map((mapping) =>
            mapping.tableName === state.currentTableKey
              ? { ...mapping, customizations: newCustomization }
              : mapping,
          ),
        },
      }
    }
    default:
      return state
  }
}

type SchemaStateContextProps = SchemaReducerState & {
  isLoading: boolean
  isError: boolean
}

const defaultSchemaStateContext: SchemaStateContextProps = {
  schema: defaultSchema,
  currentTableKey: 'futureSales',
  columns: [],
  order: [],
  visibility: {},
  isLoading: false,
  isError: false,
}
type SchemaActionContextProps = {
  setCurrentTableKey: () => void
  setSchema: (newShema: Schema) => void
  setOrder: (order: Array<keyof Customizations>) => void
  setVisibility: (visi: Visibility) => void
  updateSchemaVisibility: () => void
}

const defaultSchemaActionContext: SchemaActionContextProps = {
  setCurrentTableKey: () => {},
  setSchema: (_newShema: Schema) => {},
  setOrder: (_order: Array<keyof Customizations>) => {},
  setVisibility: (_visi: Visibility) => {},
  updateSchemaVisibility: () => {},
}

const SchemaStateContext = React.createContext<SchemaStateContextProps>(defaultSchemaStateContext)
const SchemaActionContext = React.createContext<SchemaActionContextProps>(
  defaultSchemaActionContext,
)

const SchemaProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, defaultSchemaReducerState)
  const { schema, currentTableKey, order, visibility, columns } = state

  const actions = React.useMemo(
    () => ({
      setSchema: (newShema: Schema) => {
        dispatch({ type: schemaActions.SET_SCHEMA, payload: newShema })
      },

      setOrder: (newOrder: Array<keyof Customizations>) => {
        dispatch({ type: schemaActions.SET_ORDER, payload: newOrder })
      },
      setVisibility: (visi: Visibility) => {
        dispatch({ type: schemaActions.SET_VISIBILITY, payload: visi })
      },
      updateSchemaVisibility: () => {
        dispatch({ type: schemaActions.UPDATE_SCHEMA_VISIBILITY })
      },
      setCurrentTableKey: () => {
        dispatch({ type: schemaActions.SET_CURRENT_TABLE_KEY })
      },
    }),
    [],
  )

  const { data: fetchedSchema, isLoading, isError } = useGetSchema()

  React.useEffect(() => {
    if (
      fetchedSchema?.tableMappings !== undefined &&
      compareObjectsDifferent(fetchedSchema, defaultSchema)
    ) {
      const standardizedPersonnnalSchema = {
        ...fetchedSchema,
        tableMappings: defaultSchema.tableMappings.map(
          (defaultMapping) =>
            fetchedSchema.tableMappings.find(
              (fetchedMapping) => fetchedMapping.tableName === defaultMapping.tableName,
            ) || defaultMapping,
        ),
      }

      dispatch({ type: schemaActions.SET_SCHEMA, payload: standardizedPersonnnalSchema })
    }
  }, [fetchedSchema])

  React.useEffect(() => {
    dispatch({ type: schemaActions.FILL_ORDER_FROM_SCHEMA })
    dispatch({ type: schemaActions.FILL_COLUMNS_FROM_SCHEMA })
    dispatch({ type: schemaActions.FILL_VISIBILITY_FROM_SCHEMA })
  }, [schema, currentTableKey])

  React.useEffect(() => {
    const schemaVisibility = getStateCurrentVisibility(state)
    const stateVisibility = visibility
    if (order.length !== 0 && compareObjectsDifferent(schemaVisibility, stateVisibility)) {
      dispatch({ type: schemaActions.UPDATE_SCHEMA_VISIBILITY })
    }
  }, [visibility])

  React.useEffect(() => {
    const schemaOrder = getStateCurrentOrder(state)
    if (order.length !== 0 && compareObjectsDifferent(order, schemaOrder)) {
      dispatch({ type: schemaActions.UPDATE_SCHEMA_ORDER })
    }
  }, [order])

  const stateProviderValue = React.useMemo<SchemaStateContextProps>(
    () => ({
      schema,
      currentTableKey,
      columns,
      order,
      visibility,
      isLoading,
      isError,
    }),
    [schema, currentTableKey, columns, order, visibility, isLoading, isError],
  )
  return (
    <SchemaStateContext.Provider value={stateProviderValue}>
      <SchemaActionContext.Provider value={actions}>{children}</SchemaActionContext.Provider>
    </SchemaStateContext.Provider>
  )
}

const useSchemaState = () => React.useContext(SchemaStateContext)
const useSchemaAction = () => React.useContext(SchemaActionContext)

export { SchemaProvider, useSchemaState, useSchemaAction }
export type { Visibility, TableData }
export { getStateCurrentCustomization }
