import { AuctionLightDTO, defaultAuctionLightDTO } from 'models'
import React from 'react'

type AuctionContextType = {
  auction: AuctionLightDTO
  setAuction: React.Dispatch<React.SetStateAction<AuctionLightDTO>>
}

const AuctionContext = React.createContext<AuctionContextType>({
  auction: defaultAuctionLightDTO,
  setAuction: () => {},
})

const AuctionProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [auction, setAuction] = React.useState<AuctionLightDTO>(defaultAuctionLightDTO)

  const providerValue = React.useMemo(
    () => ({
      auction,
      setAuction,
    }),
    [auction],
  )

  return <AuctionContext.Provider value={providerValue}>{children}</AuctionContext.Provider>
}

const useAuction = () => React.useContext(AuctionContext)

export { AuctionContext, AuctionProvider, useAuction }
