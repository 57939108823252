import { useMutation } from '@tanstack/react-query'
import { useAlert } from 'contexts'
import { Vehicle } from 'models'
import { VehicleUpdateResponse } from 'models/dtos/vehicleUpdateResponse'
import React from 'react'
import { currentQueryService } from 'utils/api/queries'

const useUpdateVehicle = (body: Partial<Vehicle>) => {
  const { displayAlert } = useAlert()

  const query = useMutation<VehicleUpdateResponse, Error>({
    mutationKey: ['useUpdateVehicle', body],
    mutationFn: () => currentQueryService.updateVehicle(body),
  })
  React.useEffect(() => {
    if (query.isError) {
      displayAlert(query.error?.message || 'Erreur pendant la mise à jour du véhicule', 'error')
    }
  }, [query.isError])

  return query
}

export { useUpdateVehicle }
