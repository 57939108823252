import React from 'react'

import { Customizations } from 'models'

import VehicleSheetLinesGroup from '../VehicleSheetLinesGroup'

const VehicleSheetSectionInternalAdmin = () => {
  const internalAdminDisplayedItems: (keyof Customizations)[] = [
    /// col 1
    'no',
    'entityId',
    'licensePlate',
    'vin',
    /// col 2
    'dateFirstReg',
    'color',
    'typeRegDoc',
    'bodyTypeRegDoc',
    // col 3
    'fuelType',
    'numberOfSeats',
    'typeSpare',
    'make',
    // col 4
    'version',
    'taxHp',
    'locationName',
    'providedMileage',
  ]

  return (
    <VehicleSheetLinesGroup
      arrayOfKeys={internalAdminDisplayedItems}
      className="grid content-around h-full grid-flow-col grid-cols-3 col-span-3 grid-rows-4 "
    />
  )
}

export default VehicleSheetSectionInternalAdmin
