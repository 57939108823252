import {
  VehiclePartial,
  VehicleDetail,
  Status,
  VehicleDocument,
  Prestation,
  VehicleInformation,
  VehiclePricing,
  VehicleAuction,
  VehicleLocation,
  VehicleVendorInformation,
  defaultVehiclePartial,
  defaultVehicleDetail,
  defaultVehicleInformation,
  defaultVehiclePricing,
  defaultVehicleAuction,
  defaultStatus,
} from '../subtypes'

type Vehicle = {
  // OK
  no: string
  entityId: string
  contactId: string
  vehiclePartial: VehiclePartial
  vehicleDetail: VehicleDetail
  status: Status
  documents: Array<VehicleDocument> | null
  photos: Array<VehicleDocument> | null
  prestations: Array<Prestation>

  // Different in AuctionLine
  information: VehicleInformation
  pricing: VehiclePricing

  // Doesn't exist in AuctionLine
  auction: null | VehicleAuction
  pickupLocation: null | string
  location: VehicleLocation | null
  vendorInformation: VehicleVendorInformation | null
}

const defaultVehicle: Vehicle = {
  no: '',
  entityId: '',
  contactId: '',
  vehiclePartial: defaultVehiclePartial,
  vehicleDetail: defaultVehicleDetail,
  pickupLocation: null,
  location: null,

  vendorInformation: {
    providedMileage: null,
    providedFRE: '',
    providedEstimation: '',
    priceReserve: '',
    priceReserveExclVat: '',
    providedVehicleDescription: null,
    referenceVendeur: null,
  },
  information: defaultVehicleInformation,
  pricing: defaultVehiclePricing,
  auction: defaultVehicleAuction,

  prestations: [],
  status: defaultStatus,
  documents: [],
  photos: [
    {
      fileStorageId: '',
      filename: '',
      doctype: null,
      attributes: null,
      url: null,
    },
  ],
}

export type {
  Vehicle,
  VehiclePartial,
  VehicleDetail,
  Status,
  VehicleDocument,
  Prestation,
  VehicleInformation,
  VehiclePricing,
  VehicleAuction,
  VehicleLocation,
  VehicleVendorInformation,
}

export {
  defaultVehicle,
  defaultVehiclePartial,
  defaultVehicleDetail,
  defaultVehicleInformation,
  defaultVehiclePricing,
  defaultVehicleAuction,
  defaultStatus,
}
