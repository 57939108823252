/* eslint-disable camelcase */
import EmptyDataParagraph from 'components/common/EmptyDataParagraph'
import FetchErrorParagraph from 'components/common/FetchErrorParagraph'
import MaterialTable from 'components/dataTable/MaterialTable'
import { MRT_Row } from 'material-react-table'
import { TableData } from 'models'
import { UniversalVehicle } from 'models/universalData'
import React from 'react'
import { defaultUniversalVehicle } from 'utils/helpers/extractViewModels'
import { useLoading, useCheckData } from 'utils/hooks'
import { useGetStockFromEntity } from 'utils/hooks/queries/useGetStockFromEntity'
import useVehicleModal from 'utils/hooks/useVehicleModal'

const StockPage = () => {
  const { data: stockData, isLoading, isError } = useGetStockFromEntity()
  const { Loading } = useLoading()
  const { setOpenModal, VehicleModal } = useVehicleModal()
  const { dataAfterCheck, hasData, isDataEmpty } = useCheckData<UniversalVehicle>(
    stockData,
    defaultUniversalVehicle,
  )
  const [vehicleModalActiveId, setVehicleModalActiveId] = React.useState<string>('')
  const stock: Array<UniversalVehicle> = dataAfterCheck
  const displayedTitle = `Véhicules en stock : ${hasData ? stock.length : 0}`
  const handleClick = (row: MRT_Row<TableData>) => {
    setVehicleModalActiveId(row.original.no as string)
    setOpenModal(true)
  }
  if (isLoading) return <Loading />
  if (isError) return <FetchErrorParagraph />
  if (isDataEmpty) return <EmptyDataParagraph customSentence="Pas de véhicule en stock" />
  return (
    <>
      <MaterialTable
        data={stock}
        onClick={handleClick}
        key="stock"
        title={displayedTitle}
      />
      <VehicleModal
        id={vehicleModalActiveId}
        onClose={() => setVehicleModalActiveId('')}
      />
    </>
  )
}

export default StockPage
