/* eslint-disable @typescript-eslint/no-explicit-any */

import { AutocompleteChangeReason } from '@mui/material'
import { ComboBox } from 'components/common'
import { useEntities, useWaitingFilesCount } from 'contexts'
import { defaultEntity, Entity } from 'models'
import React from 'react'
import { orphansLabel } from 'utils/constants'
import { entityDisplayName } from 'utils/helpers'
import { theme } from 'utils/theme'

const NavBarSellerFamily = () => {
  const { selectedParent, parentEntities, isLoading, setSelectedChild, setSelectedParent } =
    useEntities()

  const { setWaitingFilesCount } = useWaitingFilesCount()

  const noParentLabel: Entity = {
    ...defaultEntity,
    name: orphansLabel,
  }

  const parentOptions = [...parentEntities, noParentLabel]

  const handleParentChange = (
    _event: React.SyntheticEvent<Element, Event>,
    option: Entity | null,
    reason: AutocompleteChangeReason,
  ) => {
    setSelectedParent(null)
    setSelectedChild(null)
    setWaitingFilesCount(0)
    if (reason === 'clear') {
      return
    }
    setSelectedParent(option as Entity)
  }

  return !isLoading ? (
    <ComboBox
      options={parentOptions}
      getOptionLabel={(option: Entity) => entityDisplayName(option, true)}
      getOptionKey={(option: Entity) => option.id}
      selectedOption={selectedParent}
      onSelection={handleParentChange}
      key="parent"
      boxStyle={
        selectedParent
          ? undefined
          : {
              border: `3px solid ${theme.palette.primary.main}`,
            }
      }
    />
  ) : null
}

export default NavBarSellerFamily
