import { useQuery } from '@tanstack/react-query'
import { AuctionDTO } from 'models/dtos'
import { currentQueryService } from 'utils/api/queries'

const useGetAuction = (auctionNo: string) => {
  const query = useQuery<AuctionDTO, Error>({
    queryKey: ['useGetAuction', auctionNo],
    queryFn: () => currentQueryService.getAuction(auctionNo),
    retry: false,
  })
  return query
}

export { useGetAuction }
