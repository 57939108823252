import React from 'react'
import CustomColumnsPanel from 'components/dataTable/CustomColumnsPanel'

import { useGenericModal } from '.'

const useCustomColumnsModal = () => {
  const { DisplayedModal, setOpenModal, openModal } = useGenericModal({
    handleKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Escape') {
        setOpenModal(false)
      }
      if (e.key === 'Enter') {
        e.preventDefault()
        setOpenModal(false)
      }
    },
  })

  const CustomColumnsModal = () => {
    return (
      <DisplayedModal>
        <CustomColumnsPanel setOpen={setOpenModal} />
      </DisplayedModal>
    )
  }
  return {
    setOpenModal,
    openModal,
    CustomColumnsModal,
  }
}

export default useCustomColumnsModal
