import { Checkbox, FormControlLabel } from '@mui/material'
import { VPautoLogo } from 'components/logos'
import React from 'react'
import { PATHS } from 'utils/constants'
import { rememberMeCookie } from 'utils/cookies'

const SignInPage = () => {
  // TODO: Check if "remember me" cookie is connected to anything
  const [isRememberMeChecked, setIsRememberMeChecked] = React.useState<boolean>(
    rememberMeCookie.get(),
  )

  const handleRememberMeChange = () => {
    setIsRememberMeChecked(!isRememberMeChecked)
  }

  return (
    <div className="flex flex-col items-center w-screen h-screen">
      <div className="flex flex-col items-center justify-around w-1/2 m-auto h-2/5">
        <VPautoLogo />
        <p className="text-5xl font-bold text-secondary">Extranet VPauto</p>
        <p>Vous devez vous connecter afin d&apos;accéder aux fonctionnalités.</p>
        <FormControlLabel
          label="Se souvenir de moi"
          control={
            <Checkbox
              name="mailConsent"
              color="secondary"
              checked={isRememberMeChecked}
              onChange={handleRememberMeChange}
            />
          }
        />
        <a
          href={PATHS.authB2C}
          className="flex items-center justify-center w-1/2 h-10 text-center text-white rounded-md bg-primary-main disabled:bg-slate-300"
        >
          Se connecter en tant qu&apos;apporteur
        </a>

        <a
          href={window.location.hostname === 'localhost' ? PATHS.authLocalB2B : PATHS.authB2B}
          className="underline underline-offset-2 text-secondary disabled:text-slate-300"
        >
          Se connecter en tant qu&apos;Admin VP Auto
        </a>
      </div>
    </div>
  )
}

export default SignInPage
