import { Entity } from 'models/dtos'
import { entityDisplayName } from './entityDisplayName'

const sortEntities = (entitiesArray: Array<Entity> | null | undefined) => {
  if (!entitiesArray || entitiesArray.length === 0) {
    return []
  }
  return entitiesArray.sort((a, b) =>
    entityDisplayName(a)
      .toLowerCase()
      .localeCompare(entityDisplayName(b).toLowerCase(), undefined, {
        numeric: false,
        sensitivity: 'base',
      }),
  )
}

export { sortEntities }
