import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useEntities } from 'contexts/EntitiesContext'
import { useAuthenticatedUser } from 'contexts/UserContext'
import { Schema } from 'models/schema'
import { currentQueryService } from 'utils/api/queries'
import { isEntityDefault } from 'utils/helpers'
import { defaultSchema } from 'utils/constants'

const useGetSchema = () => {
  const { authenticatedUser } = useAuthenticatedUser()
  const { selectedParent, selectedChild } = useEntities()

  const userId = authenticatedUser?.clientPrincipal?.userId as string
  const companyId = selectedChild?.id || selectedParent?.id || ''

  const query = useQuery<Schema, Error>({
    queryKey: ['useGetSchema', userId, companyId],
    queryFn: () => currentQueryService.getSchema(userId, companyId),
    enabled: Boolean(userId && companyId),
  })
  return query
}

export { useGetSchema }
