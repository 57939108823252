type AddressInfo = {
  freeformAddress: string
  streetNumber: string
  streetName: string
  country: string
  countrySubdivision: string
  localName: string
  countryCode: string
  postalCode: string
  countryCodeIso3: string
  countrySecondarySubdivision: string
  municipality: string
  municipalitySubdivision: string
  latitude: number | null
  longitude: number | null
}

const defaultAddress: AddressInfo = {
  freeformAddress: '',
  streetNumber: '',
  streetName: '',
  country: '',
  countrySubdivision: '',
  localName: '',
  countryCode: '',
  postalCode: '',
  countryCodeIso3: '',
  countrySecondarySubdivision: '',
  municipality: '',
  municipalitySubdivision: '',
  latitude: null,
  longitude: null,
}

type AllAddresses = {
  location: Array<AddressInfo> | null | []
  deliveryLocation: Array<AddressInfo> | null | []
  invoicingLocation: Array<AddressInfo> | null | []
}

const defaultAllAddresses = {
  location: [{ ...defaultAddress }] as Array<AddressInfo>,
  deliveryLocation: [{ ...defaultAddress }] as Array<AddressInfo>,
  invoicingLocation: [{ ...defaultAddress }] as Array<AddressInfo>,
} as const

type AllAddressesKeys = keyof typeof defaultAllAddresses

// found in entity and contact
export { defaultAddress, defaultAllAddresses }
export type { AddressInfo, AllAddresses, AllAddressesKeys }
