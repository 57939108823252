import { defaultDateISO8601 } from 'models/utils/isoDate'
import { Schema } from 'models/schema'

import {
  auctionLightTableLabels,
  futureAuctionVehiclesTableLabels,
  saleResultVehiclesTableLabels,
  searchResultTableLabels,
  stockPageTableLabels,
  waitingFilesPageTableLabels,
} from 'utils/labels'

const defaultSchema: Schema = {
  // id: '',
  companyId: '',
  userId: '',
  // type: 0,
  type: 'AdminUser',
  // createdAt: defaultDateISO8601,
  // updatedAt: defaultDateISO8601,
  tableMappings: [
    { tableName: 'futureSales', customizations: auctionLightTableLabels },
    { tableName: 'futureSalesDetail', customizations: futureAuctionVehiclesTableLabels },
    { tableName: 'waitingFiles', customizations: waitingFilesPageTableLabels },
    { tableName: 'salesResult', customizations: auctionLightTableLabels },
    { tableName: 'salesResultDetail', customizations: saleResultVehiclesTableLabels },
    { tableName: 'stock', customizations: stockPageTableLabels },
    { tableName: 'search', customizations: searchResultTableLabels },
  ],
  // interval: {
  //   id: '',
  //   schemaId: '',
  //   userId: '',
  //   companyId: '',
  //   executionSchedule: {
  //     startTime: defaultDateISO8601,
  //     intervalSeconds: 0,
  //     specificTimes: [defaultDateISO8601],
  //   },
  //   lastExecutedAt: null,
  //   status: '',
  //   createdAt: defaultDateISO8601,
  //   updatedAt: null,
  // },
}

export { defaultSchema }
