import React from 'react'
import { VehicleSheet } from 'components'
import useFullScreenModal from './useFullScreenModal'
import useLoading from './useLoading'
import { useGetVehicleByNo } from './queries/useGetVehicleByNo'

const useVehicleModal = () => {
  const { setOpenModal, DisplayedFullScreenModal } = useFullScreenModal()

  const VehicleModal = ({ id, onClose }: { id: string; onClose: () => void }) => {
    const { Loading } = useLoading()
    const { vehicleData, isLoading, refetch } = useGetVehicleByNo(id)
    const [isCarouselFull, setIsCarouselFull] = React.useState<boolean>(false)

    React.useEffect(() => {
      if (id) {
        refetch()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return (
      <DisplayedFullScreenModal onClose={onClose}>
        {isLoading ? (
          <Loading />
        ) : (
          <VehicleSheet
            vehicle={vehicleData}
            isCarouselFull={isCarouselFull}
            setIsCarouselFull={setIsCarouselFull}
          />
        )}
      </DisplayedFullScreenModal>
    )
  }

  return {
    setOpenModal,
    VehicleModal,
  }
}

export default useVehicleModal
