import { generateTimeLineFilter } from './generateTimeLineFilter'

class OdataFilterBuilder {
  private query: string = ''
  // private query: string = ''

  add(condition: string): OdataFilterBuilder {
    this.query += condition
    return this
  }

  entityIdFilter(entityId: string): OdataFilterBuilder {
    const filter: string = `entityId eq '${entityId}'`
    this.query += filter
    return this
  }

  timeLineFilter(timeLine: 'past' | 'future'): OdataFilterBuilder {
    const filter = generateTimeLineFilter(timeLine)

    this.query += filter
    return this
  }

  and(condition?: string): OdataFilterBuilder {
    const filter: string = condition === undefined ? ` and ` : ` and ${condition}`
    this.query += filter
    return this
  }

  or(condition?: string): OdataFilterBuilder {
    const filter: string = condition === undefined ? ` or ` : ` or ${condition}`
    this.query += filter
    return this
  }

  build(): string {
    return this.query
  }
}

export { OdataFilterBuilder }
