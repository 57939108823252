import { AuctionLine, VehiclePartial, VehicleDetail, defaultAuctionLine } from 'models/dtos'
import {
  AuctionLineInformation,
  AuctionLinePricing,
  AuctionLineHistory,
} from 'models/dtos/subtypes'

type UniversalAuctionDetail = {
  // TODO: replace by universal vehicle data (need to update universal data)
  no: AuctionLine['no']
  passingOrderNo: AuctionLine['passingOrderNo']
  licensePlate: VehiclePartial['licensePlate']
  color: VehiclePartial['color']
  type: VehiclePartial['type']
  id: VehicleDetail['id']
  dateFirstReg: VehiclePartial['dateFirstReg']
  version: VehicleDetail['version']
  mileage: AuctionLineInformation['mileage']
  coteArgusPT: AuctionLinePricing['coteArgusPT']
  coteArgusKm: AuctionLinePricing['coteArgusKm']
  derniereOffreVentePhysique: AuctionLineHistory['derniereOffreVentePhysique']
  derniereOffreVenteElectro: AuctionLineHistory['derniereOffreVenteElectro']
  nombrePassageVente: AuctionLineHistory['nombrePassageVente']
  reserveHF: AuctionLinePricing['reserveHF']
  estimationVPAuto: AuctionLinePricing['estimationVPAuto']
  percentPT: string | null // Custom type
  percentCK: string | null // Custom type
  tvaRecuperable: AuctionLinePricing['tvaRecuperable']
  observationsVendeur: AuctionLineInformation['observationsVendeur']
}

const defaultUniversalAuctionDetail: UniversalAuctionDetail = {
  no: defaultAuctionLine.no,
  passingOrderNo: defaultAuctionLine.passingOrderNo,
  licensePlate: defaultAuctionLine.vehiclePartial.licensePlate,
  color: defaultAuctionLine.vehiclePartial.color,
  type: defaultAuctionLine.vehiclePartial.type,
  dateFirstReg: defaultAuctionLine.vehiclePartial.dateFirstReg,
  id: defaultAuctionLine.vehicleDetail.id,
  version: defaultAuctionLine.vehicleDetail.version,
  estimationVPAuto: defaultAuctionLine.pricing.estimationVPAuto,
  coteArgusPT: defaultAuctionLine.pricing.coteArgusPT,
  coteArgusKm: defaultAuctionLine.pricing.coteArgusKm,
  reserveHF: defaultAuctionLine.pricing.reserveHF,
  percentPT: null, // Assuming this is calculated later
  percentCK: null, // Assuming this is calculated later
  mileage: defaultAuctionLine.information.mileage,
  observationsVendeur: defaultAuctionLine.information.observationsVendeur,
  derniereOffreVentePhysique: defaultAuctionLine.history.derniereOffreVentePhysique,
  derniereOffreVenteElectro: defaultAuctionLine.history.derniereOffreVenteElectro,
  tvaRecuperable: defaultAuctionLine.pricing.tvaRecuperable,
  nombrePassageVente: defaultAuctionLine.history.nombrePassageVente,
}

export type { UniversalAuctionDetail }
export { defaultUniversalAuctionDetail }
