import { useQueries } from '@tanstack/react-query'
import { useEntities } from 'contexts'
import { AuctionLightDTO } from 'models/dtos'
import { currentQueryService } from 'utils/api/queries'
import { generateAuctionLib } from 'utils/helpers'

const useGetAuctionsLight = (timeLine: 'past' | 'future') => {
  const { filteringEntitiesIds } = useEntities()

  const results = useQueries({
    queries: filteringEntitiesIds.map((entityId) => ({
      queryKey: ['useGetAuctionsLight', entityId, timeLine],
      queryFn: () => {
        if (!entityId) return Promise.resolve()
        return currentQueryService.getAuctionsLight(entityId, timeLine)
      },
      retry: false,
    })),
  })

  const isLoading = results.some((result) => result.isLoading)
  const isError = results.some((result) => result.isError)
  const rawData = isError
    ? []
    : results
        .filter((result) => result.data !== undefined)
        .reduce((accumulator: AuctionLightDTO[], result) => {
          return [...accumulator, ...(result.data || [])]
        }, [])

  const data = rawData.map((auctionLight) => generateAuctionLib(auctionLight))

  return { data, isLoading, isError }
}

export { useGetAuctionsLight }
