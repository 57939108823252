import { AuctionLightDTO, defaultAuctionLightDTO } from 'models/dtos'

type UniversalAuctionLight = AuctionLightDTO & { lib: string }

const defaultUniversalAuctionLight: UniversalAuctionLight = {
  ...defaultAuctionLightDTO,
  lib: '',
}

export { defaultUniversalAuctionLight }
export type { UniversalAuctionLight }
