import { DateISO8601 } from 'models'

const dateFormats = {
  usStringSlash: /^\d{4}\/\d{2}\/\d{2}$/,
  frStringLongSlash: /^\d{2}\/\d{2}\/\d{4}$/,
  frStringShortSlash: /^\d{2}\/\d{2}\/\d{2}$/,
  usStringDash: /^\d{4}-\d{2}-\d{2}$/,
  frStringLongDash: /^\d{2}-\d{2}-\d{4}$/,
  frStringShortDash: /^\d{2}-\d{2}-\d{4}$/,
  iso: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/,
} as const

type DateFormatName = keyof typeof dateFormats

const isDateCorrectlyFormatted = (dateString: string, formats: Array<DateFormatName>) => {
  return formats.some((format) => {
    const regex = new RegExp(dateFormats[format])
    return regex.test(dateString)
  })
}

const detectDateFormat = (dateString: string) => {
  return Object.keys(dateFormats)
    .map((formatName) => {
      if (isDateCorrectlyFormatted(dateString, [formatName as DateFormatName])) {
        return formatName as DateFormatName
      }
      return null
    })
    .filter((format) => format !== null)[0]
}

const formatDateShortenYearFR = (dateString: string): string => {
  if (!isDateCorrectlyFormatted(dateString, ['frStringLongSlash', 'frStringShortSlash'])) {
    return ''
  }
  const parts = dateString.toString().split('/')
  const shortYear = parts[2].slice(-2)
  // Reorder the parts to DD/MM/YY format
  const convertedDate = `${parts[0]}/${parts[1]}/${shortYear}`
  return convertedDate
}

const formatDateFlipADateString = (dateString: string, customSeparator?: string): string => {
  if (
    !isDateCorrectlyFormatted(dateString, [
      'frStringLongSlash',
      'frStringShortSlash',
      'usStringSlash',
    ])
  ) {
    return '(date error)'
  }
  const separator = customSeparator || '/'
  const parts = dateString.split(separator)
  // Reorder the parts to DD/MM/YYYY format
  const convertedDate = `${parts[2]}/${parts[1]}/${parts[0]}`
  return convertedDate
}

const formatDateStringSToIso = (dateString: string, customSeparator?: string): string => {
  if (!isDateCorrectlyFormatted(dateString, ['usStringSlash'])) {
    return '(date error)'
  }
  const separator = customSeparator || '/'
  const parts = dateString.split(separator)
  // Reorder the parts to DD/MM/YYYY format
  const convertedDate = `${parts[2]}/${parts[1]}/${parts[0]}`
  return convertedDate
}

const formatDateFlipADateStringYYYYMMDD = (dateString: string, separator: string): string => {
  const parts = dateString.split(separator)
  // Reorder the parts to DD/MM/YYYY format
  const convertedDate = `${parts[2]}/${parts[1]}/${parts[0]}`
  return convertedDate
}

const formatDateIsoToFr = (dateString: DateISO8601 | null | ''): string => {
  if (!dateString) {
    return '-'
  }
  const parts = dateString.split('T')
  return parts[0]
}

const displayDateAndHour = (date: string, hour: string) => {
  if (!date) return '-'
  if (!hour) return date
  return `${date} à ${hour}`
}

export {
  isDateCorrectlyFormatted,
  formatDateFlipADateString,
  formatDateShortenYearFR,
  formatDateFlipADateStringYYYYMMDD,
  formatDateIsoToFr,
  detectDateFormat,
  displayDateAndHour,
}
