/* eslint-disable camelcase */
import React from 'react'
import { useLoading } from 'utils/hooks'
import { ImmatSearchInput } from 'components'
import { normaliseImmat } from 'utils/helpers/format'
import useVehicleModal from 'utils/hooks/useVehicleModal'
import { useGetVehicles } from 'utils/hooks/queries'
import { TableData } from 'contexts'
import { MRT_Row } from 'material-react-table'
import MaterialTable from 'components/dataTable/MaterialTable'

const SearchPage = () => {
  // TODO: Solve delay when changing entity!
  const { Loading } = useLoading()
  const { setOpenModal, VehicleModal } = useVehicleModal()

  const [immatToSearch, setImmatToSearch] = React.useState<string>('')
  const [vehicleModalActiveId, setVehicleModalActiveId] = React.useState<string>('')

  const { vehiclesUniversalData, isGetVehicleLoading } = useGetVehicles(
    'licensePlate',
    immatToSearch,
  )

  const hasVehicleData = vehiclesUniversalData && vehiclesUniversalData?.length !== 0

  const handleSearch = (immat: string) => {
    setImmatToSearch(normaliseImmat(immat))
  }

  const handleClick = (row: MRT_Row<TableData>) => {
    setVehicleModalActiveId(row.original.no as string)
    setOpenModal(true)
  }

  if (isGetVehicleLoading) {
    return (
      <>
        <ImmatSearchInput onSearch={handleSearch} />
        <Loading />
      </>
    )
  }

  if (immatToSearch.length === 0 && !isGetVehicleLoading) {
    return <ImmatSearchInput onSearch={handleSearch} />
  }

  return (
    <>
      <ImmatSearchInput onSearch={handleSearch} />

      {hasVehicleData ? (
        <>
          <MaterialTable
            data={vehiclesUniversalData}
            onClick={handleClick}
            key="future-sales"
            title={`Résultats de recherche pour "${immatToSearch}"`}
            // TODO Download
            // disableDownload: true,
          />

          <VehicleModal
            id={vehicleModalActiveId}
            onClose={() => setVehicleModalActiveId('')}
          />
        </>
      ) : (
        <p>
          Pas de résultat à afficher pour &ldquo;
          <span className="font-bold">{immatToSearch}&ldquo;</span>
        </p>
      )}
    </>
  )
}

export default SearchPage
