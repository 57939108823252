import { defaultAuctionDTO } from 'models/dtos'
import { compareObjectsDifferent } from 'utils/helpers'
import { extractFutureAuctionVehiclesViewModel } from 'utils/helpers/extractViewModels'
import { useGetAuction } from './useGetAuction'

const useGetAuctionVehicles = (auctionNo: string) => {
  const { data: auctionFetched, isLoading, error } = useGetAuction(auctionNo)
  const isAuctionValid =
    auctionFetched && compareObjectsDifferent(auctionFetched, defaultAuctionDTO)

  const auction = isAuctionValid ? auctionFetched : defaultAuctionDTO
  const vehiclesViewModels = extractFutureAuctionVehiclesViewModel(auction)
  return { data: vehiclesViewModels, isLoading, error, auction }
}

export { useGetAuctionVehicles }
