import { useMutation } from '@tanstack/react-query'
import { useAlert, useAuthenticatedUser, useEntities, useSchemaState } from 'contexts'
import { Schema, Vehicle } from 'models'
import { VehicleUpdateResponse } from 'models/dtos/vehicleUpdateResponse'
import React from 'react'
import { currentQueryService, UpdateSchemaBody } from 'utils/api/queries'

const useUpdateSchema = () => {
  const { authenticatedUser } = useAuthenticatedUser()
  const { selectedParent, selectedChild } = useEntities()
  const { schema } = useSchemaState()
  const { displayAlert } = useAlert()
  const userId = authenticatedUser.clientPrincipal?.userId as string
  const companyId = selectedChild?.id || selectedParent?.id || ''

  const schemaWithUserId = {
    ...(() => {
      const { id, createdAt, updatedAt, ...rest } = schema
      return rest
    })(),
    userId,
    companyId,
  }

  const updateBody: UpdateSchemaBody = {
    schema: schemaWithUserId,
    userEmail: authenticatedUser.clientPrincipal?.userDetails as string,
  }

  const query = useMutation<string, Error>({
    mutationKey: ['useUpdateSchema', schema.id],
    mutationFn: () => currentQueryService.updateSchema(updateBody),
  })
  React.useEffect(() => {
    if (query.isError) {
      displayAlert(query.error?.message || 'Erreur pendant la mise à jour du schema', 'error')
    }
    if (query.isSuccess) {
      displayAlert('Préférences mises à jour avec succès', 'success')
    }
  }, [query.isError, query.isSuccess])

  return query
}

export { useUpdateSchema }
