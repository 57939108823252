import { TableKey } from 'models/schema'

const customPanelTitles: Record<TableKey, string> = {
  futureSales: 'Ventes futures',
  futureSalesDetail: 'Détails de vente future',
  salesResult: 'Résultats des ventes',
  salesResultDetail: 'Détails de résultats de vente',
  search: 'Recherche',
  stock: 'Stock',
  waitingFiles: 'Dossiers en attentes',
}

export { customPanelTitles }
