import { useQuery } from '@tanstack/react-query'
import { useAlert } from 'contexts'
import { FilesUrlResponse } from 'models/dtos'
import React from 'react'
import { currentQueryService } from 'utils/api/queries'

const useGetFilesUrls = (ids: Array<string>) => {
  const { displayAlert } = useAlert()
  const result = useQuery<FilesUrlResponse, Error>({
    queryKey: ['useGetAuction', ids],
    queryFn: () => currentQueryService.getFilesStorageUrl(ids),
  })

  const { isLoading, isError, isSuccess, error } = result

  React.useEffect(() => {
    if (isError) {
      displayAlert(error?.message || 'Erreur pendant la recupération des photos', 'error')
    }
  }, [error])

  const idsAndUrls = result.data?.urls || []

  const filesUrls = result.data?.urls.map((link) => link.url) || []

  return { idsAndUrls, filesUrls, isLoading, isError, isSuccess }
}

export { useGetFilesUrls }
