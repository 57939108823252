type AuctionLineInformation = {
  mileage: number
  fre: string
  taxi: boolean
  autoEcole: boolean
  premiereMain: boolean
  observationsVendeur: string | null
  garantieConstructeur: boolean
  expirationGarantie: string | null
  isRegistrationDocument: boolean
  isRegistrationDocumentCopy: boolean
  isRegistrationDocumentFIV: boolean
  isRegistrationDocumentMissing: boolean
  statutCG: string | null
  statutCarnetEntretien: string | null
  garantiePremium: boolean
  expirationGarantiePremium: string | null
  doubleCle: boolean
  nonRoulant: boolean
  urlInspection: string | null
  urlImage360: string | null
  observationAcheteur: string | null
  dateCreationDossier: string | null
  noteBilanExpert: number
}

const defaultAuctionLineInformation: AuctionLineInformation = {
  mileage: 0,
  fre: '',
  taxi: false,
  autoEcole: false,
  premiereMain: false,
  observationsVendeur: null,
  garantieConstructeur: false,
  expirationGarantie: null,
  isRegistrationDocument: false,
  isRegistrationDocumentCopy: false,
  isRegistrationDocumentFIV: false,
  isRegistrationDocumentMissing: false,
  statutCG: null,
  statutCarnetEntretien: null,
  garantiePremium: false,
  expirationGarantiePremium: null,
  doubleCle: false,
  nonRoulant: false,
  urlInspection: null,
  urlImage360: null,
  observationAcheteur: null,
  dateCreationDossier: null,
  noteBilanExpert: 0,
}

export type { AuctionLineInformation }
export { defaultAuctionLineInformation }
