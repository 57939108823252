const ROLES = {
  NONE: {
    translationKey: 'None',
    roleValue: 0,
  },
  BUYER: {
    translationKey: 'Buyer',
    roleValue: 1,
  },
  SELLER: {
    translationKey: 'Seller',
    roleValue: 2,
  },
  VPFR: {
    translationKey: 'VPfr',
    roleValue: 4,
  },
  VPPRO: {
    translationKey: 'VPPro',
    roleValue: 8,
  },
  OPTIM: {
    translationKey: 'OptimVO',
    roleValue: 16,
  },
  EXTRANET: {
    translationKey: 'Extranet',
    roleValue: 32,
  },
} as const

const roleValues = Object.values(ROLES).map((role) => role.roleValue)
const numberOfPossibleRoleValues = (2 ** (roleValues.length - 1)) as number

type Role = {
  translationKey: string
  roleValue: number
}
type RoleKeys = keyof typeof ROLES
type RoleTranslationKeys = (typeof ROLES)[RoleKeys]['translationKey']
// TODO: Add 64 to RoleValues type
// type RoleValues = typeof ROLES[RoleKeys]['roleValue']
type RoleValues = number
type RolesPossibleValues = number

export { ROLES, numberOfPossibleRoleValues }
export type { RolesPossibleValues, RoleTranslationKeys, RoleValues, Role }
