/* eslint-disable camelcase */
import { TableData, useSchemaState } from 'contexts'

type FormatProps = {
  title: string
  data: Array<TableData>
}

const useDownLoadMaterialTable = ({ title, data }: FormatProps) => {
  // Manage title
  const currentDate = new Date().toLocaleDateString()
  const csvFileName = `VpAuto_Extranet_${currentDate}_${title}.csv`
  // Format CSV Data
  const { columns, visibility } = useSchemaState()

  const displayedColumns = columns
    .map((col) => {
      if (visibility[col.id as keyof typeof visibility] === true) return col.header as string
      return null
    })
    .filter((n) => n !== null)
    .join(';')

  const header = `\uFEFF ${displayedColumns} \n`

  const generateBodyLine = (dataLine: TableData) => {
    const bodyLine = columns
      .map((col) => {
        if (visibility[col.id as keyof typeof visibility] === false) {
          return null
        }
        return dataLine[col.id as keyof typeof dataLine]
      })
      .filter((n) => n !== null)
      .join(';')
    return bodyLine
  }

  const body = data.map((dataLine: TableData) => generateBodyLine(dataLine)).join('\n')

  const cleanedCSVData = `${header}${body}`.replace(/(\d),(\d)/g, '$1$2')

  return { csvFileName, cleanedCSVData }
}

export default useDownLoadMaterialTable
