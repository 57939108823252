import { Customizations } from 'models'

const futureAuctionVehiclesTableLabels: Customizations = {
  passingOrderNo: {
    label: 'Ord.',
    isDisplayed: true,
  },
  licensePlate: {
    label: 'Immat.',
    isDisplayed: true,
  },
  color: {
    label: 'Coul',
    isDisplayed: true,
  },
  type: {
    label: 'Ge',
    isDisplayed: true,
  },
  no: {
    label: 'Réf.',
    isDisplayed: true,
  },
  dateFirstReg: {
    label: 'MEC',
    isDisplayed: true,
  },
  version: {
    label: 'Type Commercial',
    isDisplayed: true,
  },
  mileage: {
    label: 'KM',
    isDisplayed: true,
  },
  coteArgusPT: {
    label: 'PT',
    isDisplayed: true,
  },
  coteArgusKm: {
    label: 'CK',
    isDisplayed: true,
  },
  derniereOffreVentePhysique: {
    label: 'Ret. Physique',
    isDisplayed: true,
  },
  derniereOffreVenteElectro: {
    label: 'Ret. Electro',
    isDisplayed: true,
  },
  nombrePassageVente: {
    label: 'NP',
    isDisplayed: true,
  },
  reserveHF: {
    label: 'Reserve',
    isDisplayed: true,
  },
  estimationVPAuto: {
    label: 'Est.',
    isDisplayed: true,
  },
  percentPT: {
    label: '%PT',
    isDisplayed: true,
  },
  percentCK: {
    label: '%CK',
    isDisplayed: true,
  },
  tvaRecuperable: {
    label: 'TVA',
    isDisplayed: true,
  },
  observationsVendeur: {
    label: 'Observat°',
    isDisplayed: true,
  },
}

export { futureAuctionVehiclesTableLabels }
