/* eslint-disable camelcase */
import React from 'react'
import { useSchemaAction, useSchemaState } from 'contexts'
import { customPanelTitles } from 'utils/constants/customPanelTitles'

import Button from '@mui/material/Button'

import { defaultSchema } from 'utils/constants'
import { Customizations } from 'models/schema'

type CustomColumnsPanelProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const CustomColumnsPanel = ({ setOpen }: CustomColumnsPanelProps) => {
  const checkboxSize = '20px'
  const { currentTableKey, schema } = useSchemaState()
  const { setSchema } = useSchemaAction()

  const [currentCustomisation, setCurrentCustomisation] = React.useState<Customizations>(
    schema.tableMappings.find((mapping) => mapping.tableName === currentTableKey)
      ?.customizations as Customizations,
  )

  const saveSchema = () => {
    setSchema({
      ...schema,
      tableMappings: schema.tableMappings.map((mapping) =>
        mapping.tableName === currentTableKey
          ? { ...mapping, customizations: currentCustomisation }
          : mapping,
      ),
    })
  }

  const toggleLine = (line: keyof Customizations) => {
    setCurrentCustomisation({
      ...currentCustomisation,
      [line]: {
        ...currentCustomisation[line],
        isDisplayed: !currentCustomisation[line]?.isDisplayed,
      },
    })
  }

  const handleCheckBoxKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    line: keyof Customizations,
  ) => {
    e.stopPropagation()
    if (e.key === 'Enter') {
      toggleLine(line)
    }
    if (e.key === 'Escape') {
      setOpen(false)
    }
  }

  const handleConfirm = () => {
    setOpen(false)
    saveSchema()
  }

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()
    if (e.key === 'Enter') {
      e.preventDefault()
      setOpen(false)
      saveSchema()
    }
    if (e.key === 'Escape') {
      setOpen(false)
    }
  }

  const editLine = (line: keyof Customizations, value: string) => {
    setCurrentCustomisation({
      ...currentCustomisation,
      [line]: {
        ...currentCustomisation[line],
        label: value,
      },
    })
  }
  const handleCancel = () => {
    setCurrentCustomisation(
      schema.tableMappings.find((mapping) => mapping.tableName === currentTableKey)
        ?.customizations as Customizations,
    )
  }

  const handleDefault = () => {
    setCurrentCustomisation(
      defaultSchema.tableMappings.find((mapping) => mapping.tableName === currentTableKey)
        ?.customizations as Customizations,
    )
  }

  return (
    <>
      <div
        className="flex flex-col bg-white "
        style={{ height: 'calc(100% - 45px)' }}
      >
        <div>
          <div className="flex items-center w-full h-12">{`Colonnes personnalisées: ${customPanelTitles[currentTableKey]}`}</div>
          <div className="flex items-center w-full h-12 p-2 mb-1 font-bold bg-primary">
            <div className="w-28">Actif</div>
            <div className="w-64">Nom Propriété</div>
            <div className="w-1/3">Nom Affiché</div>
          </div>
        </div>
        <div className="overflow-y-auto">
          {currentCustomisation
            ? Object.keys(currentCustomisation).map((line: string) => (
                <div
                  key={line}
                  className="flex items-center w-full px-2 py-1 mb-1 hover:bg-gray-300"
                >
                  <div className="flex items-center w-28">
                    <input
                      type="checkbox"
                      checked={
                        currentCustomisation[line as keyof typeof currentCustomisation]?.isDisplayed
                      }
                      onChange={() => toggleLine(line as keyof Customizations)}
                      style={{ width: checkboxSize, height: checkboxSize, zIndex: 10 }}
                      onKeyDown={(e) => handleCheckBoxKeyDown(e, line as keyof Customizations)}
                    />
                  </div>
                  <div className="w-64">
                    <span>{line}</span>
                  </div>
                  <input
                    type="text"
                    placeholder={line}
                    value={currentCustomisation[line as keyof Customizations]?.label as string}
                    onChange={(e) => editLine(line as keyof Customizations, e.target.value)}
                    className="w-full px-1 bg-gray-300 border border-gray-500 rounded-sm"
                    style={{ zIndex: 2 }}
                    onKeyDown={handleInputKeyDown}
                  />
                </div>
              ))
            : ''}
        </div>
      </div>

      <div
        className="flex justify-around w-full mt-2"
        style={{ height: '45px', zIndex: 1 }}
      >
        <div className="border-2 rounded-lg border-success-main">
          <Button
            onClick={handleConfirm}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleConfirm()
            }}
            tabIndex={0}
            color="success"
          >
            CONFIRMER
          </Button>
        </div>
        <div className="border-2 rounded-lg border-error-main border-px">
          <Button
            onClick={handleCancel}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
                handleCancel()
              }
            }}
            tabIndex={0}
            color="error"
          >
            ANNULER
          </Button>
        </div>

        <div className="border-2 rounded-lg border-warning-main border-primary-main border-px">
          <Button
            onClick={handleDefault}
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault()
              e.stopPropagation()
              handleDefault()
            }}
            tabIndex={0}
            color="primary"
          >
            DEFAUT
          </Button>
        </div>
      </div>
    </>
  )
}

export default CustomColumnsPanel
