import React from 'react'
import SettingsSuggest from '@mui/icons-material/SettingsSuggest'
import useCustomColumnsModal from 'utils/hooks/useCustomColumnsModal'

const CustomizeColumnsButton = () => {
  const { setOpenModal, CustomColumnsModal } = useCustomColumnsModal()
  const handleClick = () => {
    setOpenModal(true)
  }
  return (
    <>
      <button
        type="button"
        onClick={handleClick}
        className="flex items-center justify-center w-36"
      >
        <SettingsSuggest />
        Noms
      </button>
      <CustomColumnsModal />
    </>
  )
}

export default CustomizeColumnsButton
