/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
import React from 'react'
import { TableData, useAlert, useSchemaAction, useSchemaState, Visibility } from 'contexts'
import {
  MRT_Row,
  MRT_RowData,
  MRT_ShowHideColumnsButton,
  MRT_TableContainer,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  useMaterialReactTable,
} from 'material-react-table'
import useDownLoadMaterialTable from 'utils/hooks/useDownLoadMaterialTable'
import { compareObjectsDifferent } from 'utils/helpers'
import { Box, CircularProgress } from '@mui/material'
import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material'
import ErrorIcon from '@mui/icons-material/Error'
import { Customizations } from 'models'
import { CustomizeColumnsButton } from 'components/dataTable/CustomizeColumnsButton'
import { useUpdateSchema } from 'utils/hooks/queries/useUpdateSchema'
import SaveIcon from '@mui/icons-material/Save'

import { theme } from 'utils/theme'

import { DataTableDownLoadButton } from '../DataTableDownLoadButton'
import SaveSchemaButton from '../SaveSchemaButton'

type MaterialTableProps<T extends MRT_RowData> = {
  data: Array<T>
  title: string
  onClick: (row: MRT_Row<TableData>) => void
}

const MaterialTable = <T extends MRT_RowData>({ data, onClick, title }: MaterialTableProps<T>) => {
  const noClickList: Array<keyof Customizations> = ['reserveHF']
  const tableRef = React.useRef(null)
  const { csvFileName, cleanedCSVData } = useDownLoadMaterialTable({ title, data })
  const { order, visibility, currentTableKey, columns } = useSchemaState()
  // const { mutate, isPending, isIdle, isSuccess, isError, error } = useUpdateSchema()
  // const { displayAlert } = useAlert()
  // const UpdateButton = () => {
  //   if (isPending) {
  //     return <CircularProgress />
  //   }
  //   if (isError) {
  //     displayAlert(error.message, 'error')
  //     return <ErrorIcon />
  //   }
  //   if (isSuccess) {
  //     displayAlert('Préférences mises à jour avec succès', 'success')
  //   }

  //   return <SaveIcon />
  // }

  const { setOrder, setVisibility } = useSchemaAction()

  const table = useMaterialReactTable({
    data,
    layoutMode: 'semantic',
    columns,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    initialState: {
      columnVisibility: visibility,
      columnOrder: order,
    },
    defaultColumn: {
      maxSize: 300,
      minSize: 1.5,
      size: 1,
    },

    enablePagination: false,
    enableBottomToolbar: false,

    muiTableBodyCellProps: ({ row, column }) => ({
      onClick: () => {
        if (!noClickList.includes(column.id as keyof Customizations)) {
          onClick(row)
        }
      },
      sx: {
        cursor: 'pointer',
      },
    }),
  })

  const tableState = table.getState()

  React.useEffect(() => {
    table.setColumnOrder(order)
  }, [order])

  React.useEffect(() => {
    table.setColumnVisibility(visibility)
  }, [visibility])

  React.useEffect(() => {
    if (compareObjectsDifferent(visibility, tableState.columnVisibility)) {
      setVisibility(tableState.columnVisibility as Visibility)
    }
  }, [tableState.columnVisibility])

  React.useEffect(() => {
    if (compareObjectsDifferent(tableState.columnOrder, order)) {
      setOrder(tableState.columnOrder as Array<keyof Customizations>)
    }
  }, [tableState.columnOrder])

  return (
    <Box
      sx={{
        width: '100%',
      }}
      key={`${currentTableKey}-${order.length}`}
      ref={tableRef.current}
    >
      <Box
        // TITLE and TOOLBAR
        sx={{
          'display': 'flex',

          'backgroundColor': 'inherit',

          'borderRadius': '4px',

          'flexDirection': 'row',

          'gap': '16px',

          'justifyContent': 'space-between',

          'padding': '0px',

          '@media max-width: 768px': {
            flexDirection: 'column',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'justify-between',
            justifyContent: 'space-between',
            width: '100%',
            padding: '4px 16px',
            borderRadius: '8px 8px 0px 0px',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.contrastText,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'justify-between',
              // justifyContent: 'space-between',
            }}
          >
            <p className="flex items-center">{title}</p>
            <CustomizeColumnsButton />
            <DataTableDownLoadButton
              fileName={csvFileName}
              csvData={cleanedCSVData}
            />
            <SaveSchemaButton />
          </Box>
          <div className="flex">
            <MRT_ToggleFiltersButton
              table={table}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.secondary.contrastText,
              }}
            />
            <MRT_ShowHideColumnsButton
              table={table}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.secondary.contrastText,
              }}
            />

            <MRT_ToggleDensePaddingButton
              table={table}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.secondary.contrastText,
              }}
            />
          </div>
        </Box>
      </Box>

      <MRT_TableContainer
        table={table}
        className="border border-primary"
      />
    </Box>
  )
}

export default MaterialTable
