/* eslint-disable camelcase */
import EmptyDataParagraph from 'components/common/EmptyDataParagraph'
import FetchErrorParagraph from 'components/common/FetchErrorParagraph'
import MaterialTable from 'components/dataTable/MaterialTable'
import { useAlert } from 'contexts'
import { MRT_Row } from 'material-react-table'
import { TableData } from 'models'
import { UniversalVehicle } from 'models/universalData'
import React from 'react'
import { defaultUniversalVehicle } from 'utils/helpers/extractViewModels'
import { useLoading, useCheckData } from 'utils/hooks'
import { useGetWaitingFilesFromEntity } from 'utils/hooks/queries/useGetWaitingFilesFromEntity'
import useVehicleModal from 'utils/hooks/useVehicleModal'

const WaitingFilesPage = () => {
  const { data: waitingFilesData, isLoading, isError } = useGetWaitingFilesFromEntity()
  const { displayAlert } = useAlert()
  const { Loading } = useLoading()
  const { setOpenModal, VehicleModal } = useVehicleModal()
  const { dataAfterCheck, hasData, isDataEmpty } = useCheckData<UniversalVehicle>(
    waitingFilesData,
    defaultUniversalVehicle,
  )
  const [vehicleModalActiveId, setVehicleModalActiveId] = React.useState<string>('')

  const waitingFiles: Array<UniversalVehicle> = dataAfterCheck
  const displayedTitle = `Véhicule(s) en attente : ${hasData ? waitingFiles.length : 0}`

  const handleClick = (row: MRT_Row<TableData>) => {
    setVehicleModalActiveId(row.original.no as string)
    setOpenModal(true)
  }

  // TODO: Gérer dans useGetWaitingFiles
  React.useEffect(() => {
    if (isError) {
      displayAlert('Erreur lors du chargement des dossiers en attente', 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  if (isLoading) return <Loading />
  if (isError) return <FetchErrorParagraph />
  if (isDataEmpty) return <EmptyDataParagraph customSentence="Aucun véhicule en attente." />

  return (
    <>
      <MaterialTable
        data={waitingFiles}
        title={displayedTitle}
        onClick={handleClick}
      />

      <VehicleModal
        id={vehicleModalActiveId}
        onClose={() => setVehicleModalActiveId('')}
      />
    </>
  )
}

export default WaitingFilesPage
