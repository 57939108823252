import { Customizations } from 'models'

const stockPageTableLabels: Customizations = {
  nbJ: {
    label: 'NJ',
    isDisplayed: true,
  },
  no: {
    label: 'Réf',
    isDisplayed: true,
  },
  licensePlate: {
    label: 'Immat',
    isDisplayed: true,
  },
  bodyType: {
    label: 'Genre',
    isDisplayed: true,
  },
  make: {
    label: 'Marque',
    isDisplayed: true,
  },
  model: {
    label: 'Modèle',
    isDisplayed: true,
  },
  dateProduction: {
    label: 'Date création',
    isDisplayed: true,
  },
  dateDelivery: {
    label: 'Date livraison',
    isDisplayed: true,
  },
  auctionStartDateTime: {
    label: 'Date vente',
    isDisplayed: true,
  },
  locationName: {
    label: 'Parc Stockage',
    isDisplayed: true,
  },
  auctionStatus: {
    label: 'Statut',
    isDisplayed: true,
  },
  administrativeStatus: {
    label: 'Sous statut',
    isDisplayed: true,
  },
}

export { stockPageTableLabels }
