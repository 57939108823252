import { useQuery } from '@tanstack/react-query'
import { useTranslator, useAlert } from 'contexts'
import { Vehicle } from 'models'
import React from 'react'
import { currentQueryService } from 'utils/api/queries'
import { defaultUniversalVehicle, extractUniversalVehicle } from 'utils/helpers/extractViewModels'

const useGetVehicleByNo = (no: Vehicle['no']) => {
  const { translateStatusVehicle } = useTranslator()
  const { displayAlert } = useAlert()

  const result = useQuery<Vehicle, Error>({
    queryKey: ['useGetVehicleByNo', no],
    queryFn: () => currentQueryService.getVehicleByNo(no),
    retry: false,
  })

  const { isLoading, isError, error, refetch } = result

  const translatedRawData = translateStatusVehicle(result.data)

  const vehicleData = isError ? defaultUniversalVehicle : extractUniversalVehicle(translatedRawData)

  React.useEffect(() => {
    if (isError) {
      displayAlert(error.message, 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  return {
    isLoading,
    isError,
    refetch,
    error,
    vehicleData,
  }
}

export { useGetVehicleByNo }
