import { useVehicle } from 'contexts'
import React from 'react'
import { vehicleSheetLabels } from 'utils/labels'

const VehicleSheetEquipments = () => {
  const currentVehicle = useVehicle()
  return (
    <div
      className="grid w-full row-span-2 p-4 overflow-y-auto bg-white border border-black rounded-md"
      key="equipments"
    >
      <span className="mb-1 font-bold ">{vehicleSheetLabels.equipments?.label}</span>
      {currentVehicle.equipments.map((equ) => {
        return <p key={equ.name}>- {equ.name}</p>
      })}
    </div>
  )
}

export default VehicleSheetEquipments
