import React from 'react'
import { CircularProgress } from '@mui/material'
import { ReserveQueueTask, defaultReserveQueueTask, useReserveQueue } from 'contexts'
import { useUpdateVehicle } from 'utils/hooks/queries/useUpdateVehicle'

const NavBarReserveStatus = () => {
  // TODO: Check how to move logic to custom hook
  const [currentTask, setCurrentTask] = React.useState<ReserveQueueTask>(defaultReserveQueueTask)
  const [allowMutation, setAllowMutation] = React.useState(false)
  const [reserveQueueStatus, setReserveQueueStatus] = React.useState<'ongoing' | 'done'>('done')
  const {
    isPending: isMutationPending,
    status: mutationStatus,
    mutate,
  } = useUpdateVehicle({
    no: currentTask.no,
    vendorInformation: {
      priceReserve: currentTask.newReserve.toString(),
    },
  })

  const { queue, hasTask, numberOfSuccess, changeTaskStatus, onTaskSuccess } = useReserveQueue()

  const newTasks = queue.filter((task) => task.status === 'new')
  const numberOfNewTask = newTasks.length

  const pendingTasks = queue.filter((task) => task.status === 'pending')
  const numberOfPendingTask = pendingTasks.length

  const numberOfNewOrPendingTask = numberOfNewTask + numberOfPendingTask

  const errorTasks = queue.filter((task) => task.status === 'error')
  const numberOfErrorTask = errorTasks.length

  // Prevent tab or window closing conditionally
  React.useEffect(() => {
    if (hasTask) {
      const handleOnBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault()
      }
      window.addEventListener('beforeunload', handleOnBeforeUnload, { capture: true })
      return () => {
        window.removeEventListener('beforeunload', handleOnBeforeUnload, { capture: true })
      }
    }
    return undefined
  }, [hasTask])

  // Get first task with status 'new'
  React.useEffect(() => {
    if (reserveQueueStatus === 'ongoing') return
    if (numberOfNewTask === 0) {
      setCurrentTask(defaultReserveQueueTask)
      return
    }

    const firstNewTask = queue.find((task) => task.status === 'new') as ReserveQueueTask
    setCurrentTask(firstNewTask)
    setReserveQueueStatus('ongoing')
    setAllowMutation(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue, reserveQueueStatus])

  // send query
  React.useEffect(() => {
    if (allowMutation) {
      mutate()
    }
    setAllowMutation(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowMutation])

  React.useEffect(() => {
    if (mutationStatus === 'idle' || currentTask.no === '') return
    if (mutationStatus === 'pending') {
      changeTaskStatus(currentTask.no, 'pending')
      return
    }

    setReserveQueueStatus('done')

    if (mutationStatus === 'error') {
      changeTaskStatus(currentTask.no, 'error')
      return
    }

    onTaskSuccess(currentTask.no)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationStatus])

  const returnDisplayedStatus = () => {
    // eslint-disable-next-line max-len
    return `Reserves: ${numberOfNewOrPendingTask}  en cours, ${numberOfSuccess} succès, ${numberOfErrorTask} erreurs à traiter`
  }

  // manage general status

  const displayedStatus = returnDisplayedStatus()

  return (
    <div
      className={`${
        numberOfErrorTask > 0
          ? 'text-red-700 font-bold border-2 border-primary-main bg-secondary-light'
          : 'text-white '
      } p-2 mr-2 w-full flex justify-between align-middle `}
    >
      {displayedStatus}
      {isMutationPending && <CircularProgress />}
    </div>
  )
}

export default NavBarReserveStatus
