import React from 'react'
import { useVehicle } from 'contexts'
import './style.css'
import ImageGallery from 'react-image-gallery'
import { useGetFilesUrls } from 'utils/hooks/queries'
import { useLoading } from 'utils/hooks'
import { isFileAPhoto } from 'utils/helpers'

const VehicleSheetSectionCarousel: React.FC<{
  isCarouselFull: boolean
  setIsCarouselFull: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ isCarouselFull, setIsCarouselFull }) => {
  const vehicle = useVehicle()
  const { Loading } = useLoading()

  const listOfPhotoIds =
    vehicle.photos
      ?.map((doc) => (isFileAPhoto(doc) ? doc.fileStorageId : null))
      .filter((elt) => elt !== null) || []
  const { filesUrls: picturesArray, isLoading } = useGetFilesUrls(listOfPhotoIds as string[])
  const hasAnyPhoto = listOfPhotoIds.length > 0
  const [isCarouselFullScreen, setIsCarouselFullScreen] = React.useState(false)
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const refGallery = React.useRef<ImageGallery | null>(null)

  if (isLoading) {
    return (
      <div className="flex flex-wrap items-center justify-center w-full h-full text-xl shadow-xl rounded-xl">
        <Loading />
      </div>
    )
  }

  if (!hasAnyPhoto) {
    return (
      <div className="flex flex-wrap items-center justify-center w-full h-full text-xl shadow-xl rounded-xl">
        Ce véhicule n&apos;a pas (encore) de photos
      </div>
    )
  }

  const setBool = (value: boolean) => {
    setIsCarouselFull(value)
  }
  const toggleCarouselFullScreen = () => {
    setIsCarouselFullScreen(!isCarouselFullScreen)
    setBool(!isCarouselFull)
  }

  const images = picturesArray.map((imgUrl: string) => ({
    original: imgUrl,
    thumbnail: imgUrl,
    originalClass: isCarouselFullScreen
      ? 'image-gallery-slide-fullScreen'
      : 'image-gallery-slide-smallScreen',
  }))

  const upDateIndex = () => {
    setCurrentIndex(refGallery.current?.getCurrentIndex() ?? 0)
  }
  const handleScreenChange = () => {
    upDateIndex()
    toggleCarouselFullScreen()
  }

  const handleClick = () => {
    upDateIndex()
    window.open(picturesArray[currentIndex], '_blank')
  }

  return (
    <ImageGallery
      items={images}
      useBrowserFullscreen={false}
      showPlayButton={false}
      onScreenChange={handleScreenChange}
      ref={refGallery}
      showIndex
      startIndex={currentIndex}
      onClick={handleClick}
      onMouseOver={upDateIndex}
    />
  )
}

export default VehicleSheetSectionCarousel
