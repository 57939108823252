import { useQueries } from '@tanstack/react-query'
import { useEntities, useTranslator } from 'contexts'
import { Vehicle } from 'models'
import { defaultVehicle, Entity } from 'models/dtos'
import { currentQueryService } from 'utils/api/queries'
import { isEntityDefault } from 'utils/helpers'
import { extractUniversalVehicles } from 'utils/helpers/extractViewModels'

const useGetWaitingFilesFromEntity = () => {
  const { childrenEntities, selectedChild, isOrphanSelected } = useEntities()
  const { translateStatusVehicleArray } = useTranslator()

  const allFilteringEntitiesIds = ['']

  const results = useQueries({
    queries: allFilteringEntitiesIds.map((entityId) => ({
      queryKey: ['useGetWaitingFilesFromEntity', entityId],
      queryFn: () => {
        if (!entityId) return Promise.resolve([])
        return currentQueryService.getWaitingFilesFromEntity(entityId)
      },
      staleTime: Infinity,
    })),
  })
  const isLoading = results.some((result) => result.isLoading)
  const isError = results.some((result) => result.isError)

  const rawData = isError
    ? []
    : results
        .filter((result) => result.data !== undefined)
        .reduce((accumulator: Vehicle[], result) => {
          return [...accumulator, ...(result.data || [])]
        }, [])

  const data = translateStatusVehicleArray(rawData)

  const vehiclesViewModels = data
    ? extractUniversalVehicles(data)
    : extractUniversalVehicles([defaultVehicle])

  return { data: vehiclesViewModels, isLoading, isError }
}

export { useGetWaitingFilesFromEntity }
