import React from 'react'
import { UniversalVehicle } from 'models/universalData'
import { FocusTrap } from '@mui/base'
import { VehicleContext } from 'contexts'
import { theme } from 'utils/theme'
import VehicleSheetSectionCarousel from './VehicleSheetSectionCarousel'
import VehicleSheetSectionCondition from './VehicleSheetSectionCondition'
import VehicleSheetSectionInternalAdmin from './VehicleSheetSectionInternalAdmin'
import VehicleSheetSectionModelInfo from './VehicleSheetSectionModelInfo'

const VehicleSheet = ({
  vehicle,
  isCarouselFull,
  setIsCarouselFull,
}: {
  vehicle: UniversalVehicle
  isCarouselFull: boolean
  setIsCarouselFull: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <VehicleContext.Provider value={vehicle}>
      <FocusTrap open>
        <div
          tabIndex={-1}
          className="grid w-full h-full grid-cols-12 gap-2 p-2 overflow-y-auto text-sm lg:pl-24 md:pl-2 grid-rows-24"
        >
          <div
            className="grid grid-cols-4 col-span-11 row-span-5 p-2 overflow-y-auto shadow-xl rounded-xl "
            style={{
              backgroundColor: theme.palette.secondary.light,
            }}
          >
            <VehicleSheetSectionInternalAdmin />
          </div>
          <div className="col-span-6 col-start-1 row-start-6 overflow-y-auto row-span-14">
            <VehicleSheetSectionCarousel
              isCarouselFull={isCarouselFull}
              setIsCarouselFull={setIsCarouselFull}
            />
          </div>
          <div
            className="grid col-span-5 col-start-7 row-start-6 gap-0 p-2 shadow-xl row-span-19 rounded-xl"
            style={{
              backgroundColor: theme.palette.secondary.light,
            }}
          >
            <VehicleSheetSectionModelInfo />
          </div>
          <div
            className="flex flex-col justify-around col-span-6 col-start-1 row-span-5 p-2 overflow-y-auto shadow-xl row-start-20 rounded-xl"
            style={{
              backgroundColor: theme.palette.secondary.light,
            }}
          >
            <VehicleSheetSectionCondition />
          </div>
        </div>
      </FocusTrap>
    </VehicleContext.Provider>
  )
}

export default VehicleSheet
