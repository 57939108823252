import { useQueries } from '@tanstack/react-query'
import { useTranslator, useAlert, useEntities } from 'contexts'
import { Vehicle } from 'models'
import { defaultVehicle } from 'models/dtos'
import React from 'react'
import { currentQueryService } from 'utils/api/queries'
import { extractUniversalVehicles } from 'utils/helpers/extractViewModels'

const useGetStockFromEntity = () => {
  const { translateStatus } = useTranslator()
  const { displayAlert } = useAlert()

  const { filteringEntitiesIds } = useEntities()

  const results = useQueries({
    queries: filteringEntitiesIds.map((entityId) => ({
      queryKey: ['useGetStockFromEntity', entityId],
      queryFn: () => {
        if (!entityId) return Promise.resolve([])
        return currentQueryService.getStockFromEntity(entityId)
      },
      retry: 0,
    })),
  })

  const isLoading = results.some((result) => result.isLoading)
  const isError = results.some((result) => result.isError)

  React.useEffect(() => {
    if (isError) {
      displayAlert('Erreur lors du chargement du stock', 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  const rawData = isError
    ? []
    : results
        .filter((result) => result.data !== undefined)
        .reduce((accumulator: Vehicle[], result) => {
          return [...accumulator, ...(result.data || [])]
        }, [])

  const data = rawData.map((vh) => {
    const newVh = { ...vh, status: translateStatus(vh.status) }
    return newVh
  })

  const vehiclesViewModels = data
    ? extractUniversalVehicles(data)
    : extractUniversalVehicles([defaultVehicle])

  return { data: vehiclesViewModels, isLoading, isError }
}

export { useGetStockFromEntity }
