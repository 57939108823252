import { useQueries } from '@tanstack/react-query'
import { useTranslator, useAlert, useEntities } from 'contexts'
import { Entity, Vehicle } from 'models'
import React from 'react'
import { currentQueryService } from 'utils/api/queries'
import { isEntityDefault } from 'utils/helpers'
import { extractUniversalVehicles } from 'utils/helpers/extractViewModels'

const useGetVehicles = (key: 'no' | 'licensePlate' | 'vin', value: string) => {
  const { translateStatusVehicleArray } = useTranslator()
  const { displayAlert } = useAlert()

  const { childrenEntities, selectedChild, isOrphanSelected, filteringEntitiesIds } = useEntities()

  const allFilteringEntities =
    !selectedChild && !isOrphanSelected ? [...childrenEntities] : [selectedChild]

  // TODO FIX !!!!!
  const results = useQueries({
    queries: filteringEntitiesIds.map((entityId) => ({
      queryKey: ['getVehicles', key, value, entityId],
      queryFn: () => {
        if (!entityId) return Promise.resolve()
        return currentQueryService.getVehicles(entityId, key, value)
      },
      enabled: !!key && !!value && !!entityId,
      staleTime: 0,
      retry: 1,
    })),
  })

  const isGetVehicleLoading = results.some((result) => result.isLoading)
  const isGetVehicleError = results.some((result) => result.isError)

  React.useEffect(() => {
    if (isGetVehicleError) {
      displayAlert('Error while fetching vehicles', 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetVehicleError])

  const vehiclesData = isGetVehicleError
    ? []
    : results
        .filter((result) => result.data !== undefined)
        .reduce((accumulator: Vehicle[], result) => {
          return [...accumulator, ...(result.data || [])]
        }, [])

  const vehiclesUniversalData = extractUniversalVehicles(translateStatusVehicleArray(vehiclesData))

  return {
    isGetVehicleError,
    isGetVehicleLoading,
    vehiclesUniversalData,
  }
}

export { useGetVehicles }
