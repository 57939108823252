/* eslint-disable @typescript-eslint/no-unused-vars */

import axiosInstance from 'api/axiosInstance/axiosInstance'
import axios from 'axios'
import {
  defaultContact,
  Contact,
  AuctionDTO,
  Entity,
  AuctionLightDTO,
  Vehicle,
  defaultVehicle,
  Translator,
  FilesUrlResponse,
  VehicleUpdateResponse,
  Schema,
} from 'models'
import { ENDPOINTS, defaultSchema } from 'utils/constants'
import { OdataFilterBuilder } from '../filters'
import { IQueries, UpdateSchemaBody } from './queryInterface'

const dummyQueryAdapter: IQueries = {
  getContact: async (contactId: string) => {
    if (!contactId) {
      return defaultContact
    }
    const queryParams = {
      params: {
        id: contactId,
      },
    }
    try {
      const response = await axiosInstance.get<Contact>(`/contact`, { ...queryParams })
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }
      return response.data
    } catch (error) {
      // Handle network or other Axios errors
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  getAuction: async (auctionNo: string) => {
    try {
      const queryParams = {
        params: {
          auction: auctionNo,
        },
      }
      const response = await axiosInstance.get<AuctionDTO>('/auction', {
        ...queryParams,
      })
      // Explicitly handle HTTP errors by throwing an exception
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }

      return response.data // Return the auction data if the request is successful
      // Handle network or other Axios errors
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  getAuctionsLight: async (filteringEntityId: Entity['id'], timeLine) => {
    const queryFilter = new OdataFilterBuilder()
      .entityIdFilter(filteringEntityId)
      .and()
      .timeLineFilter(timeLine)
      .build()

    const filterParams = {
      params: {
        filter: queryFilter,
        limit: 1000000,
        page: 0,
      },
    }
    try {
      const response = await axiosInstance.get<Array<AuctionLightDTO>>(
        ENDPOINTS.AUCTIONS_LIGHT,
        filterParams,
      )
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }
      return response.data
    } catch (error) {
      // Handle network or other Axios errors
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  getStockFromEntity: async (filteringEntityId: Entity['id']): Promise<Vehicle[]> => {
    const statusFilter = `status/generalStatus eq 1`
    const queryFilter = new OdataFilterBuilder()
      .entityIdFilter(filteringEntityId)
      .and(statusFilter)
      .build()
    const filterParams = {
      params: {
        filter: queryFilter,
        limit: 1000000,
        page: 0,
      },
    }
    // Make the API request
    try {
      const response = await axiosInstance.get<Array<Vehicle>>('/vehicles', filterParams)
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  getWaitingFilesFromEntity: async (filteringEntityId: Entity['id']): Promise<Vehicle[]> => {
    const statusFilter = `status/generalStatus eq 7`
    const queryFilter = new OdataFilterBuilder()
      .entityIdFilter(filteringEntityId)
      .and(statusFilter)
      .build()
    const filterParams = {
      params: {
        filter: queryFilter,
        limit: 1000000,
        page: 0,
      },
    }
    // Make the API request
    try {
      const response = await axiosInstance.get<Array<Vehicle>>('/vehicles', filterParams)

      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }
      return response.data
    } catch (error) {
      // Handle network or other Axios errors
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  getAllEntities: async (): Promise<Array<Entity>> => {
    try {
      const response = await axiosInstance.get<Array<Entity>>('/entities')
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }
      return response.data
    } catch (error) {
      // Handle network or other Axios errors
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  // TODO: Readapt once eq filters on strings are OK
  getVehicles: async (
    filteringEntityId: Entity['id'],
    key: 'no' | 'licensePlate' | 'vin',
    value: string,
  ) => {
    // TODO: manage NO
    if (key === 'no') {
      return [defaultVehicle]
    }

    const filterVehicle: Record<typeof key, string> = {
      // no: `contains(no, '${value}')`,
      licensePlate: `contains(vehiclePartial/licensePlate, '${value}')`,
      vin: `contains(vehiclePartial/vin, '${value}')`,
    }

    const queryFilter = new OdataFilterBuilder()
      .entityIdFilter(filteringEntityId)
      .and(filterVehicle[key])
      .build()

    const filterParams = {
      params: {
        filter: queryFilter,
        limit: 10,
        page: 0,
      },
    }
    try {
      const response = await axiosInstance.get<Array<Vehicle>>('/vehicles', filterParams)
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }

      return response.data
    } catch (error) {
      // Handle network or other Axios errors
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  getVehicleByNo: async (no) => {
    if (!no) {
      return defaultVehicle
    }
    try {
      const queryParams = {
        params: {
          vehicleNo: no,
        },
      }
      const response = await axiosInstance.get<Vehicle>('/vehicleByNo', {
        ...queryParams,
      })
      // Explicitly handle HTTP errors by throwing an exception
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }

      return response.data // Return the auction data if the request is successful
      // Handle network or other Axios errors
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }

    // return defaultVehicle
  },

  getStatusesObject: async () => {
    const queryParams = {
      params: {
        mode: 'object',
      },
    }
    try {
      const response = await axiosInstance.get<Translator>(ENDPOINTS.TRANSLATOR_OBJECT, queryParams)
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }
      return response.data
    } catch (error) {
      // Handle network or other Axios errors
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  getFilesStorageUrl: async (ids) => {
    const listOfIdsObjects = ids.map((photoId) => ({ id: photoId }))

    const queryBody = {
      ids: listOfIdsObjects,
    }

    try {
      const response = await axiosInstance.post<FilesUrlResponse>(ENDPOINTS.FILES, queryBody)
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }

      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  updateVehicle: async (body: Partial<Vehicle>) => {
    try {
      const response = await axiosInstance.post<VehicleUpdateResponse>(
        ENDPOINTS.VEHICLE_UPDATE,
        body,
      )
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }

      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  updateSchema: async (body: UpdateSchemaBody) => {
    try {
      const response = await axiosInstance.post<string>(ENDPOINTS.UPDATE_SCHEMA, body)
      if (response.status !== 200) {
        throw new Error(`HTTP Error: ${response.status} - ${response.statusText}`)
      }
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },

  getSchema: async (
    userId: string,
    companyId: string,
    //  userEmail: string
  ) => {
    const queryParams = {
      params: {
        userId,
        companyId,
        // userEmail,
      },
    }

    try {
      const response = await axiosInstance.get<Schema>(ENDPOINTS.GET_SCHEMA, queryParams)

      if (response.status === 204) {
        return defaultSchema
      }

      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Error: ', error)
        throw new Error(error.response?.data?.message || error.message || 'Unknown Axios error')
      }
      throw new Error('An unexpected error occurred')
    }
  },
  // getSchema: async () => {
  //   return defaultSchema
  // },
}

export { dummyQueryAdapter }
