import { Button, CircularProgress } from '@mui/material'
import React from 'react'
import { useUpdateSchema } from 'utils/hooks/queries/useUpdateSchema'
import SaveIcon from '@mui/icons-material/Save'
import ErrorIcon from '@mui/icons-material/Error'

const SaveSchemaButton = () => {
  const { mutate, isPending, isError } = useUpdateSchema()

  const updateButton = () => {
    if (isPending) {
      return (
        <CircularProgress
          sx={{ color: 'white' }}
          size={26}
        />
      )
    }
    if (isError) {
      return <ErrorIcon className="text-secondary-dark" />
    }
    return <SaveIcon className="text-white" />
  }
  return (
    <Button
      type="button"
      onClick={() => mutate()}
      disabled={isPending}
      className="text-white"
    >
      {updateButton()}
    </Button>
  )
}

export default SaveSchemaButton
