import { Entity } from 'models'

const entityDisplayName = (ent: Entity | null, isFamillyRequired?: boolean) => {
  if (!ent) {
    return ''
  }
  const displayedName =
    ent?.commercialNameInt ||
    ent?.name ||
    ent?.commercialName ||
    `${ent.firstName} ${ent.lastName}` ||
    'no Name!'

  if (
    isFamillyRequired &&
    ent.childrenCount > 0 &&
    !displayedName.toLowerCase().includes('famille')
  ) {
    return `${displayedName}(Famille)`
  }

  return displayedName
}

export { entityDisplayName }
