const generateTimeLineFilter = (timeLine: 'past' | 'future') => {
  const now = new Date()
  const year = now.getUTCFullYear()
  const month = String(now.getUTCMonth() + 1).padStart(2, '0') // Months are 0-based
  const day = String(now.getUTCDate()).padStart(2, '0')
  const today = `${year}-${month}-${day}T00:00:00Z`
  // eslint-disable-next-line max-len
  return `auction/auctionStartDateTime ${timeLine === 'future' ? 'gt' : 'lt'} '${today}'`
}

export { generateTimeLineFilter }
