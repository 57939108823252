import { Customizations } from 'models'

const vehicleSheetLabels: Customizations = {
  no: {
    label: 'Réf.int dossier: ',
    isDisplayed: true,
  },
  entityId: {
    label: 'Réf entité: ',
    isDisplayed: true,
  },
  licensePlate: {
    label: 'Immatriculation: ',
    isDisplayed: true,
  },
  vin: {
    label: 'vin: ',
    isDisplayed: true,
  },
  dateFirstReg: {
    label: 'Date première immat.: ',
    isDisplayed: true,
  },
  color: {
    label: 'Couleur: ',
    isDisplayed: true,
  },
  typeRegDoc: {
    label: 'Type carte grise: ',
    isDisplayed: true,
  },
  bodyTypeRegDoc: {
    label: 'Type carrosserie: ',
    isDisplayed: true,
  },
  fuelType: {
    label: 'Type carburant: ',
    isDisplayed: true,
  },
  numberOfSeats: {
    label: 'Nb sièges: ',
    isDisplayed: true,
  },
  equipments: {
    label: 'Equipements: ',
    isDisplayed: true,
  },
  typeSpare: {
    label: 'Type roue de secours: ',
    isDisplayed: true,
  },
  make: {
    label: 'Marque: ',
    isDisplayed: true,
  },
  version: {
    label: 'Version: ',
    isDisplayed: true,
  },
  taxHp: {
    label: 'Puissance fisc: ',
    isDisplayed: true,
  },
  locationName: {
    label: 'Lieu de stockage: ',
    isDisplayed: true,
  },
  providedMileage: {
    label: 'Kilométrage vendeur: ',
    isDisplayed: true,
  },
  providedFRE: {
    label: 'FRE: ',
    isDisplayed: true,
  },
  priceReserve: {
    label: 'Prix de réserve: ',
    isDisplayed: true,
  },
  mileage: {
    label: 'Kilométrage officiel: ',
    isDisplayed: true,
  },
  estimation: {
    label: 'Estimation: ',
    isDisplayed: true,
  },
  argusAgeAddedValue: {
    label: 'Cote argus datée: ',
    isDisplayed: true,
  },
  argusMileageAddedValue: {
    label: 'Cote argus kilométrique: ',
    isDisplayed: true,
  },
  auctionStatus: {
    label: 'Statut vente: ',
    isDisplayed: true,
  },
  administrativeStatus: {
    label: 'Statut admin.: ',
    isDisplayed: true,
  },
}

export { vehicleSheetLabels }
