/* eslint-disable camelcase */
import EmptyDataParagraph from 'components/common/EmptyDataParagraph'
import FetchErrorParagraph from 'components/common/FetchErrorParagraph'
import MaterialTable from 'components/dataTable/MaterialTable'
import { useAlert, useAuction } from 'contexts'
import { MRT_Row } from 'material-react-table'
import { TableData, AuctionLightDTO } from 'models'
import { defaultUniversalAuctionLight, UniversalAuctionLight } from 'models/universalData'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useLoading, useCheckData } from 'utils/hooks'
import { useGetAuctionsLight } from 'utils/hooks/queries'

const SalesResultPage = () => {
  const displayedTitle = 'Résultats de vente'
  const navigate = useNavigate()
  const { data: salesResultData, isLoading, isError } = useGetAuctionsLight('past')
  const { displayAlert } = useAlert()
  const { Loading } = useLoading()
  const { setAuction } = useAuction()

  const { dataAfterCheck, isDataEmpty } = useCheckData<UniversalAuctionLight>(
    salesResultData,
    defaultUniversalAuctionLight,
  )

  const handleClick = (row: MRT_Row<TableData>) => {
    const typedSale = dataAfterCheck[row.id as keyof typeof dataAfterCheck] as TableData
    if (typedSale && typedSale.no) {
      setAuction(typedSale as AuctionLightDTO)
      navigate(typedSale.no)
    }
  }

  React.useEffect(() => {
    if (isError) {
      displayAlert('Erreur lors du chargement des résultats des ventes!', 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  if (isLoading) return <Loading />
  if (isError) return <FetchErrorParagraph />
  if (isDataEmpty) return <EmptyDataParagraph customSentence="Aucune vente à afficher." />
  return (
    <MaterialTable
      data={salesResultData}
      onClick={handleClick}
      key="future-sales"
      title={displayedTitle}
    />
  )
}

export default SalesResultPage
