import { Entity } from 'models'
import React from 'react'
import { allAccountsLabel, orphansLabel } from 'utils/constants'
import { entityDisplayName } from 'utils/helpers'
import { sortEntities } from 'utils/helpers/sortEntities'
import { useGetAllEntities } from 'utils/hooks/queries/useGetAllEntities'

type EntitiesReducerState = {
  allEntities: Array<Entity>
  parentEntities: Array<Entity>
  orphanEntities: Array<Entity>

  selectedParent: Entity | null
  childrenEntities: Array<Entity>

  selectedChild: Entity | null

  filteringEntitiesIds: Array<Entity['id']>

  isAllAccountsSelected: boolean
  isOrphanSelected: boolean
}

const defaultEntitiesReducerState: EntitiesReducerState = {
  allEntities: [],
  parentEntities: [],
  orphanEntities: [],

  selectedParent: null,
  childrenEntities: [],

  selectedChild: null,
  isAllAccountsSelected: false,
  isOrphanSelected: false,
  filteringEntitiesIds: [],
}

const entitiesActions = {
  SET_FIXED_ENTITIES: 'SET_FIXED_ENTITIES',

  SET_CHILDREN_ENTITIES: 'SET_CHILDREN_ENTITIES',
  SET_FILTERING_ENTITIES_IDS: 'SET_FILTERING_ENTITIES_IDS',
  SET_SELECTED_PARENT: 'SET_SELECTED_PARENT',
  SET_SELECTED_CHILD: 'SET_SELECTED_CHILD',
  UPDATE_ALL_BOOLEANS: 'UPDATE_ALL_BOOLEANS',
  // TODO: check and delete
  REMOVE_SELECTED_PARENT: 'REMOVE_SELECTED_PARENT',
  REMOVE_SELECTED_CHILD: 'REMOVE_SELECTED_CHILD',
  REMOVE_FILTERING_ENTITY: 'REMOVE_FILTERING_ENTITY',
} as const

type EntitiesActionsKeys = keyof typeof entitiesActions
type EntitiesActions = (typeof entitiesActions)[EntitiesActionsKeys]

type EntitiesReducerAction = {
  type: EntitiesActions
  entity?: Entity | null
  entities?: Array<Entity>
}

const reducer = (
  state: EntitiesReducerState,
  action: EntitiesReducerAction,
): EntitiesReducerState => {
  switch (action.type) {
    // TODO complete with orphans and parents
    case entitiesActions.SET_FIXED_ENTITIES: {
      const all = action.entities as [Entity]
      const orphans = all.filter((ent) => !ent.parentId && ent.childrenCount === 0)
      const parents = all.filter((ent) => ent.childrenCount !== 0)

      return { ...state, allEntities: all, parentEntities: parents, orphanEntities: orphans }
    }

    case entitiesActions.SET_CHILDREN_ENTITIES:
      return {
        ...state,
        childrenEntities: action.entities as Array<Entity>,
      }

    case entitiesActions.SET_SELECTED_PARENT:
      return { ...state, selectedParent: action.entity as Entity | null }

    case entitiesActions.SET_SELECTED_CHILD:
      return { ...state, selectedChild: action.entity as Entity }

    case entitiesActions.SET_FILTERING_ENTITIES_IDS: {
      const resultArray = () => {
        if (!state.selectedParent) {
          return []
        }
        if (state.isOrphanSelected) {
          return [state.selectedChild?.id || null]
        }
        if (!state.selectedChild || state.isAllAccountsSelected) {
          return [state.selectedParent.id, ...(state.selectedParent.children as Array<string>)]
        }
        return [state.selectedChild.id]
      }

      return { ...state, filteringEntitiesIds: resultArray() as Array<string> }
    }

    case entitiesActions.UPDATE_ALL_BOOLEANS:
      return {
        ...state,
        isAllAccountsSelected:
          entityDisplayName(state.selectedChild as Entity) === allAccountsLabel,
        isOrphanSelected: entityDisplayName(state.selectedParent as Entity) === orphansLabel,
      }

    // case entitiesActions.REMOVE_SELECTED_PARENT:
    //   return { ...state, selectedParent: null }

    // case entitiesActions.REMOVE_SELECTED_CHILD:
    //   return { ...state, selectedChild: null }

    default:
      return state
  }
}

type EntitiesContextProps = {
  allEntities: EntitiesReducerState['allEntities']
  parentEntities: EntitiesReducerState['parentEntities']
  orphanEntities: EntitiesReducerState['orphanEntities']

  selectedParent: EntitiesReducerState['selectedParent']
  childrenEntities: EntitiesReducerState['childrenEntities']
  selectedChild: EntitiesReducerState['selectedChild']

  isAllAccountsSelected: boolean
  isOrphanSelected: boolean

  filteringEntitiesIds: EntitiesReducerState['filteringEntitiesIds']

  setFixedEntities: (entities: Array<Entity>) => void
  setChildrenEntities: (entities: Array<Entity>) => void

  setFilteringEntitiesIds: () => void

  // setAllEntities: (entities: Array<Entity>) => void
  // setOrphanEntities: (entities: Array<Entity>) => void

  setSelectedParent: (entity: Entity | null) => void
  setSelectedChild: (entity: Entity | null) => void

  updateAllBooleans: () => void
  isError: boolean
  isLoading: boolean

  // TODO see if really necessary
  removeSelectedParent: () => void
  removeSelectedChild: () => void
  // removeSelectedFilteringEntity: () => void
}

const defaultEntitiesContext: EntitiesContextProps = {
  allEntities: [],
  parentEntities: [],
  childrenEntities: [],
  orphanEntities: [],
  selectedParent: null,
  selectedChild: null,
  filteringEntitiesIds: [],
  setFixedEntities: (_entities) => {},
  setChildrenEntities: (_entities) => {},
  setSelectedParent: (_entity) => {},
  setFilteringEntitiesIds: () => {},

  setSelectedChild: (_entity) => {},

  updateAllBooleans: () => {},
  isLoading: false,
  isError: false,
  isAllAccountsSelected: false,
  isOrphanSelected: false,

  removeSelectedParent: () => {},
  removeSelectedChild: () => {},
  // removeSelectedFilteringEntity: () => {},
}

const EntitiesContext = React.createContext<EntitiesContextProps>(defaultEntitiesContext)

const EntitiesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, defaultEntitiesReducerState)

  const {
    allEntities,
    childrenEntities,
    parentEntities,
    orphanEntities,
    selectedChild,
    selectedParent,
    filteringEntitiesIds,
    isAllAccountsSelected,
    isOrphanSelected,
  } = state

  const { data: fetchedEntities, isLoading, isError } = useGetAllEntities()

  const setFixedEntities = (entities: Array<Entity>) => {
    sortEntities(entities)
    dispatch({ type: entitiesActions.SET_FIXED_ENTITIES, entities })
  }

  const setFilteringEntitiesIds = () => {
    dispatch({ type: entitiesActions.SET_FILTERING_ENTITIES_IDS })
  }

  const setChildrenEntities = () => {
    // TODO add conditions
    const returnChildrenEntities = () => {
      if (isOrphanSelected) return state.orphanEntities

      if (state.selectedParent)
        return state.allEntities.filter((ent) => ent.parentId === state.selectedParent?.id)

      return []
    }
    // state.selectedParent && !state.isOrphanSelected
    //   ? state.allEntities.filter((ent) => ent.parentId === state.selectedParent?.id)
    //   : []
    dispatch({ type: entitiesActions.SET_CHILDREN_ENTITIES, entities: returnChildrenEntities() })
  }

  const setSelectedParent = (entity: Entity | null) => {
    dispatch({ type: entitiesActions.SET_SELECTED_PARENT, entity })
  }

  const setSelectedChild = (entity: Entity | null) => {
    dispatch({ type: entitiesActions.SET_SELECTED_CHILD, entity })
  }

  const removeSelectedParent = () => {
    dispatch({ type: entitiesActions.REMOVE_SELECTED_PARENT })
  }

  const removeSelectedChild = () => {
    dispatch({ type: entitiesActions.REMOVE_SELECTED_CHILD })
  }

  const updateAllBooleans = () => {
    dispatch({ type: entitiesActions.UPDATE_ALL_BOOLEANS })
  }

  React.useEffect(() => {
    if (fetchedEntities) {
      setFixedEntities(fetchedEntities)
      // setOrphanEntities(fetchedEntities.filter((ent) => !ent.parentId && ent.childrenCount === 0))
    }
  }, [fetchedEntities])

  React.useEffect(() => {
    updateAllBooleans()
    setChildrenEntities()
    setFilteringEntitiesIds()
  }, [
    state.selectedChild,
    state.selectedParent,
    state.isOrphanSelected,
    state.isAllAccountsSelected,
  ])

  const providerValue = React.useMemo<EntitiesContextProps>(
    () => ({
      allEntities,
      selectedParent,
      childrenEntities,
      orphanEntities,
      selectedChild,
      filteringEntitiesIds,
      parentEntities,
      setFilteringEntitiesIds,
      setFixedEntities,
      setSelectedParent,
      setChildrenEntities,
      setSelectedChild,
      removeSelectedParent,
      removeSelectedChild,
      updateAllBooleans,
      isLoading,
      isError,
      isAllAccountsSelected,
      isOrphanSelected,
    }),
    [
      allEntities,
      selectedParent,
      childrenEntities,
      filteringEntitiesIds,
      selectedChild,
      parentEntities,
      orphanEntities,
      isLoading,
      isError,
      isAllAccountsSelected,
      isOrphanSelected,
    ],
  )
  return <EntitiesContext.Provider value={providerValue}>{children}</EntitiesContext.Provider>
}

const useEntities = () => React.useContext(EntitiesContext)

export { EntitiesProvider, useEntities }
