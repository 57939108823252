import { VehicleDocument } from 'models'

const isFileAPhoto = (entryDoc: VehicleDocument) => {
  const photoExtensions = ['.jpg', '.jpeg', '.png', '.gif']
  const name = entryDoc.filename
  const test = (extension: string) => name?.endsWith(extension)
  return photoExtensions.some(test)
}

export { isFileAPhoto }
