import { useVehicle } from 'contexts'

import React from 'react'
import { Customizations } from 'models'
import { vehicleSheetLabels } from 'utils/labels'
import VehicleSheetDocuments from '../VehicleSheetDocuments'
import VehicleSheetEquipments from '../VehicleSheetEquipments'

type VehicleSheetLinesGroupProps = { arrayOfKeys: (keyof Customizations)[]; className?: string }

const VehicleSheetLinesGroup = ({ arrayOfKeys, className }: VehicleSheetLinesGroupProps) => {
  const currentVehicle = useVehicle()

  type Display = {
    [key in keyof Partial<Customizations>]: React.ReactNode
  }

  const display: Display = {
    equipments: <VehicleSheetEquipments key="equip" />,
    documents: <VehicleSheetDocuments key="docs" />,
  }

  return (
    <div className={className}>
      {arrayOfKeys.map((key) => {
        return display[key] ? (
          display[key]
        ) : (
          <p key={key}>
            <span className="font-bold">{vehicleSheetLabels[key]?.label}</span>

            {currentVehicle[key as keyof typeof currentVehicle] as string}
          </p>
        )
      })}
    </div>
  )
}

export default VehicleSheetLinesGroup
