import { UniversalAuctionDetail } from 'models/universalData'

// TODO: No % on vehicle sheet
const addPercentCkCp = (line: UniversalAuctionDetail) => {
  const estimation = parseFloat(line.estimationVPAuto)
  const hasEstimation = Boolean(estimation)
  if (!hasEstimation) {
    return line
  }
  const numberPT = parseFloat(line.coteArgusPT)
  const canComputePercentagePT = hasEstimation && Boolean(numberPT)
  const resultPT = canComputePercentagePT
    ? `${Math.round((estimation / numberPT) * 100).toString()}%`
    : null

  const numberCK = parseFloat(line.coteArgusKm)
  const canComputePercentagrCK = hasEstimation && Boolean(numberCK)
  const resultCK = canComputePercentagrCK
    ? `${Math.round((estimation / numberCK) * 100).toString()}%`
    : null

  return { ...line, percentPT: resultPT, percentCK: resultCK }
}

export { addPercentCkCp }
