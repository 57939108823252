/* eslint-disable camelcase */
import EmptyDataParagraph from 'components/common/EmptyDataParagraph'
import FetchErrorParagraph from 'components/common/FetchErrorParagraph'
import MaterialTable from 'components/dataTable/MaterialTable'
import { useAuction, useAlert } from 'contexts'
import { MRT_Row } from 'material-react-table'
import { AuctionLightDTO, TableData } from 'models'
import {
  defaultUniversalAuctionLight,
  UniversalAuctionLight,
} from 'models/universalData/universalAuctionLight'

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useLoading, useCheckData } from 'utils/hooks'
import { useGetAuctionsLight } from 'utils/hooks/queries'

const FutureSalesTable = ({
  title,
  auctionsData,
}: {
  title: string
  auctionsData: Array<UniversalAuctionLight>
}) => {
  const navigate = useNavigate()
  const { setAuction } = useAuction()

  const handleClick = (row: MRT_Row<TableData>) => {
    if (row.original && row.original.no) {
      setAuction(row.original as AuctionLightDTO)
      navigate(row.original.no)
    }
  }

  return (
    <MaterialTable
      title={title}
      data={auctionsData}
      onClick={handleClick}
    />
  )
}

const FutureSalesPage = () => {
  const { data: auctions, isLoading, isError } = useGetAuctionsLight('future')

  const { displayAlert } = useAlert()
  const { Loading } = useLoading()
  const { dataAfterCheck, isDataEmpty } = useCheckData<UniversalAuctionLight>(
    auctions as unknown as UniversalAuctionLight[],
    defaultUniversalAuctionLight,
  )
  const futureAuctions: Array<UniversalAuctionLight> = dataAfterCheck

  React.useEffect(() => {
    if (isError) {
      displayAlert('Erreur lors du chargement des ventes futures', 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  if (isLoading) return <Loading />
  if (isError) return <FetchErrorParagraph />
  if (isDataEmpty) return <EmptyDataParagraph customSentence="Aucune vente à afficher" />
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const physicalSales = futureAuctions.filter((auction: UniversalAuctionLight) => {
    return auction.isOnlineAuction === false
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const otherSales = futureAuctions.filter((auction: UniversalAuctionLight) => {
    return auction.isOnlineAuction === true
  })

  return (
    <>
      {physicalSales.length > 0 && (
        <FutureSalesTable
          title="Ventes en salle"
          auctionsData={physicalSales}
        />
      )}
      {otherSales.length > 0 && (
        <FutureSalesTable
          title="Ventes en ligne"
          auctionsData={otherSales}
        />
      )}
    </>
  )
}

export default FutureSalesPage
